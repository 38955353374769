<script setup lang="ts">
import _ from 'lodash'
import {API_BASE_URL} from '@/env'
import { useI18n } from 'vue-i18n'
import { ref, onMounted } from 'vue';
import { BaseTree, Draggable } from '@he-tree/vue'
import '@he-tree/vue/style/default.css'

const router = useRouter()
const route = useRoute()
const { t, locale } = useI18n();

const infraTreeData = ref(undefined);
const selectedItem = ref(undefined);
const detailItem = ref(undefined);

const params = route.params as Record<string, string>;
const schoolUuid = params.id;

const { data: currentUser } = useCurrentUser2();
const isLoading = ref(false);

const buildingFacilityTypesPath = ref(getTaxonomyPath('building-facility-types', locale.value));
const reportStatusesPath = ref(getTaxonomyPath('report-statuses', locale.value));
const equipmentTypesPath = ref(getTaxonomyPath('equipment-types', locale.value));

const { data: school } = useSchool(schoolUuid)
const { data: buildings } = useBuildings(schoolUuid)
const { data: buildingFacilities } = useBuildingFacilities(schoolUuid)
const { data: buildingFacilityTypes } = useBuildingFacilityTypes(buildingFacilityTypesPath)
const { data: reportStatuses } = useReportStatuses(reportStatusesPath)
const { data: equipmentTypes } = useEquipmentTypes(equipmentTypesPath)
const { data: reports } = useReports(schoolUuid)

const tableFacilityData = ref({
  rows: [],
  columns: [
    { name: 'Facility', field_name: 'name', type: 'text' },
    { name: 'Number', field_name: 'count',type: 'text'},
  ]
})

const tablePlanData = ref({
  rows: [],
  columns: [
    { name: 'All', field_name: 'all', type: 'text' },
    { name: 'Pending', field_name: '6101',type: 'text'},
    { name: 'Approved', field_name: '6102',type: 'text'},
    { name: 'Funding unavailable', field_name: '6103',type: 'text'},
  ]
})

const tableReportData = ref({
  rows: [],
  columns: [
    { name: 'Plan title', field_name: 'name', type: 'text' },
    { name: 'Review Result', field_name: 'status',type: 'text'},
  ]
})

const tableEquipmentData = ref({
  rows: [],
  columns: [
    { name: 'Equipment', field_name: 'name', type: 'text' },
    { name: 'Number', field_name: 'count',type: 'text'},
  ]
})

const listActionItems = ref([
  { title: 'Manage Facility', value: 'manage_facility' },
  { title: 'Manage Equpipment', value: 'manage_equipment' },
]);

onMounted(async () => {
  isLoading.value = true
});

watch(
  () => [
    currentUser.value,
    school.value,
    buildings.value,
    buildingFacilities.value,
    buildingFacilityTypes.value,
    reportStatuses.value,
    equipmentTypes.value,
    reports.value,
  ],
  ([
    currentUser,
    school,
    buildings,
    buildingFacilities,
    buildingFacilityTypes,
    reportStatuses,
    equipmentTypes,
    reports,
  ]) => {
    if (
      currentUser &&
      school &&
      buildings &&
      buildingFacilities &&
      buildingFacilityTypes &&
      reportStatuses &&
      equipmentTypes &&
      reports
    ) {
      console.log('==== infrastructure page data ready =====')

      const bool = verifyTeacherSchoolPermission(currentUser, school, router);
      if (!bool) {
        router.push({ name: 'schools' })
      } else {
        createInfraTree();
        isLoading.value = false;
      }
    }
  }
);

function createInfraTree() {
  const newList: any = [];
  const schoolNode: any = getTreeObject('school','school','School', 0);

  _.each(buildings.value, (building, index) => {
    const buildingNodeName = `Building ${index + 1} (${building.field_name})`;
    const buildingNode: any = getTreeObject(building.id, 'building', buildingNodeName, building, index);

    // Loop facility
    _.each(buildingFacilityTypes.value, (facilityType, index) => {
      const foundBuildingFacility = _.find(buildingFacilities.value, (bf) => {
        return bf.field_building_facility_type === facilityType.id &&
        bf.field_building === building.id;
      })

      if (foundBuildingFacility && foundBuildingFacility.field_count > 0) {
        const bfName = `${facilityType.name} (${foundBuildingFacility.field_count})`
        const bfObj = getTreeObject(foundBuildingFacility.id, 'facility', bfName, foundBuildingFacility. index);
        buildingNode.children.push(bfObj);
      }
    });

    // Equipment
    const eqNode: any = getTreeObject(building.id + '_equipment', 'equipment', 'Equipment', building, index);
    buildingNode.children.push(eqNode);

    // Loop Report
    const filteredReports: any = _.filter(reports.value, (r) => {
      return r.field_building == building.uuid;
    });
    _.each(filteredReports, (report, index) => {
      const rpObj = getTreeObject(filteredReports.id, 'report', report.title, report, index);
        buildingNode.children.push(rpObj);
    });

    schoolNode.children.push(buildingNode);
  });

  infraTreeData.value = [schoolNode];

  if (schoolNode.children && schoolNode.children.length) {
    selectNode({data: _.cloneDeep(schoolNode.children[0])})
  }
  // console.log('schoolNode', schoolNode);
}

async function selectNode(event) {
  const data = event.data;
  console.log("Node clicked:", data);
  if (data.type === 'building') {
    selectedItem.value = data;

    // Format Detail data
    const detailResult = {};
    const result = [];
    _.each(buildingFacilityTypes.value, (facilityType, index) => {
      const foundBuildingFacility = _.find(buildingFacilities.value, (bf) => {
        return bf.field_building_facility_type === facilityType.id &&
        bf.field_building === selectedItem.value.data.id;
      })
      if (foundBuildingFacility) {
        result.push({
          name: facilityType.name,
          count: foundBuildingFacility.field_count.toString()
        });
      }
    });
    tableFacilityData.value.rows = _.cloneDeep(result);

    const filteredReports: any = _.filter(reports.value, (r) => {
      return r.field_building == selectedItem.value.data.uuid;
    });

    // Related Plan count
    const sumMapping: any = {
      all: 0,
      6101: 0,
      6102: 0,
      6103: 0
    };

    const reportCountResult = [];
    const tempRow: any = [];
    const tempObj = {};
    _.each(reportStatuses.value, (statusType, index) => {
      const items: any = _.filter(filteredReports, (r) => {
        return r.field_report_status === statusType.uuid
      });
      sumMapping[statusType.id] = items.length;
      sumMapping.all = items.length + sumMapping.all;
      tempObj[statusType.id] = items.length.toString();
    });
    tempObj.all = sumMapping.all.toString();
    tablePlanData.value.rows = [tempObj];

    const reportResult = [];
    _.each(filteredReports, (report, index) => {
      const reviewText = report.field_review_result ? report.field_review_result : '';
      reportResult.push({
        name: report.title,
        status: reviewText
      })
    });

    tableReportData.value.rows = _.cloneDeep(reportResult);
    detailItem.value = _.cloneDeep(detailResult);
  } else if (data.type === 'equipment') {
    isLoading.value = true
    selectedItem.value = data;
    const buildingData = data.data;
    const {data: tempLoad } = await useEquipments(buildingData.uuid);
    const tempRows = []
    _.each(equipmentTypes.value, (eqType, index) => {
      const foundEq = _.find(tempLoad.value, (eq) => {
        return eq.field_equipment_type === eqType.id &&
        eq.field_building === buildingData.id;
      })
      if (foundEq) {
        tempRows.push({
          name: eqType.name,
          count: foundEq.field_count.toString()
        })
      }
    });
    tableEquipmentData.value.rows = _.cloneDeep(tempRows);
    isLoading.value = false
  } else if (data.type === 'report') {
    const routeData = router.resolve({ name: 'schools-id-reports-id2?', params: {
      id: schoolUuid, id2: data.data.uuid } });
    window.open(routeData.fullPath, '_blank');
  }
}

async function selectAction(actionItem) {
  if (actionItem.value === 'manage_facility') {
    const URL = `${API_BASE_URL}/building-facility?field_building_id=${selectedItem.value.data.id}&field_school_id=${school.value.id}`;
    window.open(URL, '_blank');
  } else if (actionItem.value === 'manage_equipment') {
    const URL = `${API_BASE_URL}/equipment?field_building_id=${selectedItem.value.data.id}`;
    window.open(URL, '_blank');
  }
}

definePage({
  meta: {
    breadcrumb: 'Infrastructure',
  }
})
</script>

<template>
  <div>
    <SchoolDetailNavigation :school="school" />
  </div>
  <VCard height="100%" width="100%">
    <VLayout>
      <VMain class="infrastructure-page-container">
        <div class="pa-0 ma-0 w-100 h-100">
          <div v-if="isLoading" style="height: 600px;">
            <LoadingSpinner />
          </div>
          <VRow class='ma-0' v-if="!isLoading">
            <VCol cols="4" class="pt-2">
              <div class="tree-panel">
                <h2>Infrastructure</h2>
                <div v-if="infraTreeData">
                  <BaseTree
                    treeLine
                    v-model="infraTreeData"
                    @click:node="selectNode($event)">

                    <template #default="{ node, stat, indentStyle, tree }">
                      <div
                        :class="[
                          `tree_item tree_item_type_${node.type}`,
                          selectedItem && node.id === selectedItem.id ? 'tree_selected' : ''
                        ]">
                        <button
                          v-if="node.children.length"
                          @click="stat.open = !stat.open">
                          <VIcon v-if="stat.open" size="20" icon="tabler-caret-down"/>
                          <VIcon v-if="!stat.open" size="20" icon="tabler-caret-right"/>
                        </button>
                        {{ node.text }}
                      </div>
                    </template>
                  </BaseTree>
                </div>
              </div>
            </VCol>
            <VCol cols="8" class="pt-2">
              <div class="w-100">
                <div v-if="selectedItem">
                    <div>
                      <div
                        class="ma-5 mt-0 action-button-container">
                        <v-btn
                          color="primary">
                          Action
                          <v-menu activator="parent">
                            <v-list>
                              <v-list-item
                                v-for="(item, index) in listActionItems"
                                :key="index"
                                :value="index">
                                <v-list-item-title
                                  @click="selectAction(item)">
                                  <span v-if="item.value">{{ item.title }}</span>
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-btn>
                      </div>

                      <VCard class="ma-5">
                        <div class="information-box pb-3">
                          <h3>Building Information</h3>
                          <VRow class='ma-0 mb-2'>
                            <VCol cols="6" class="pa-0">
                              <div class="sub-title-text">Building Number</div>
                              <div class="">{{selectedItem.index + 1}}</div>
                            </VCol>
                            <VCol cols="6" class="pa-0">
                              <div class="sub-title-text">Building Name</div>
                              <div class="">{{selectedItem.data.field_name}}</div>
                            </VCol>
                          </VRow>
                        </div>
                      </VCard>             
                    </div>
                    <div v-if="selectedItem.type === 'building'">
                      <VCard class="ma-5">
                        <div class="information-box pb-3">
                          <h3>Facility</h3>
                        </div>
                        <div class="information-box pt-0 pb-3">
                          <CustomTable :data="tableFacilityData"/>
                        </div>
                      </VCard>

                      <VCard class="ma-5">
                        <div class="information-box pb-3">
                          <h3>Related Plan</h3>
                        </div>
                        <div class="information-box pt-0 pb-3">
                          <CustomTable :data="tablePlanData"/>
                        </div>
                        <div class="information-box pt-0 pb-3">
                          <CustomTable :data="tableReportData"/>
                        </div>
                      </VCard>
                    </div>

                    <div v-if="selectedItem.type === 'equipment'">
                      <VCard class="ma-5">
                        <div class="information-box pb-3">
                          <h3>Equipment</h3>
                        </div>
                        <div class="information-box pt-0 pb-3">
                          <CustomTable :data="tableEquipmentData"/>
                        </div>
                      </VCard>
                    </div>
                </div>
              </div>
            </VCol>
          </VRow>
        </div>
      </VMain>
    </VLayout>
  </VCard>
</template>

<style scoped lang="scss">
.infrastructure-page-container {
  display: flex;
  height: 100%;
  width: 100%;
  .tree-panel {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    .tree_item {
      cursor: pointer;
      padding: 5px;
    }
    .tree_selected {
      background: #D5DADD;
    }
    .tree_item_type_building {
      font-weight: bold;
      color: #0077D4;
    }
    .tree_item_type_equipment {
      color: #0077D4;
    }
    .tree_item_type_report {
      color: #0077D4;
      text-decoration: underline;
    }
  }
  .faiclity-detail-box {
    .facility-table {
      font-size: 12px;
      .row-item {
        height: 30px;
        padding: 8px;
      }
      .row-header {
        background: #F1F4F6;
        font-weight: bold;
      }
      .label-column {
        display: inline-table;
        width: 80%;
      }
      .count-column {
        display: inline-table;
        width: 20%;
      }
      .cat-column {
        display: inline-table;
        width: 25%;
      }
    }
  }
}

</style>
