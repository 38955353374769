import { API_BASE_URL } from '@/env';

export type FetchOptions = RequestInit & { headers?: Headers };

export const CSRF_URL = `${API_BASE_URL}/session/token?_format=json`

export async function fetchWithCSRFToken(fetchUrl: string,
  fetchOptions: FetchOptions,
  csrfUrl = CSRF_URL,
  token?: string
): Promise<Response> {
  if (fetchOptions.headers === undefined) {
    throw new Error('fetchOptions.headers not found');
  }
  if (token) {
    fetchOptions.headers.append('X-CSRF-Token', token);
    return fetch(fetchUrl, fetchOptions);
  }
  const [csrfTokenHeaderName, csrfTokenHeaderValue] = await getCSRFTokenHeader(csrfUrl)
  fetchOptions.headers.append(csrfTokenHeaderName, csrfTokenHeaderValue)
  return fetch(fetchUrl, fetchOptions);
};

export async function getCSRFTokenHeader(csrfUrl = CSRF_URL) {
  const csrfToken = await fetch(csrfUrl).then(response => response.text())
  return ['X-CSRF-Token', csrfToken]
}

// export async function fetchDrupal(method: 'DELETE' | 'GET' | 'PATCH' | 'POST',
//   path: string,
//   options?: FetchOptions
// ): Promise<Response> {
//   if (options === undefined) options = {};
//   options.method = method;
//   if (options.headers === undefined) options.headers = new Headers();
//   options.headers.append('Content-Type', 'application/json');
//   const url = `${API_BASE_URL}${path}`;
//   // console.log('url', url);
//   return fetchWithCSRFToken(url, options);
// }
