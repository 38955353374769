<script setup lang="ts">
import _ from 'lodash'
import {API_BASE_URL} from '@/env'
import csvDownload from 'json-to-csv-export'
import { useI18n } from 'vue-i18n'
import { insertNotification } from '@/utils/notification';
import { Attributes, Relationships } from '@/utils/drupal';
import html2pdf from 'html2pdf.js';
import type { Report } from '@/models/nodes/Report'

const router = useRouter()
const route = useRoute()
const { t, locale } = useI18n();

const params = route.params as Record<string, string>;
const schoolUuid = params.id;
const reportUuid = params.id2;

const { data: currentUser } = useCurrentUser2();

const schoolStatusesPath = ref(getTaxonomyPath('school-statuses', locale.value));
const reportStatusesPath = ref(getTaxonomyPath('report-statuses', locale.value));
const reportTypesPath = ref(getTaxonomyPath('report-types', locale.value));
const buildingFacilityTypesPath = ref(getTaxonomyPath('building-facility-types', locale.value));

const { data: school } = useSchool(schoolUuid);
const { data: reports } = useReports(schoolUuid);
const { data: schoolStatuses } = useSchoolStatus(schoolStatusesPath)
const { data: reportStatuses } = useReportStatuses(reportStatusesPath)
const { data: reportTypes } = useReportStatuses(reportTypesPath)
const { data: buildings } = useBuildings(schoolUuid);
const { data: buildingFacilities } = useBuildingFacilities(schoolUuid);
const { data: buildingFacilityTypes } = useBuildingFacilityTypes(buildingFacilityTypesPath)

const isLoading = ref(false);

// Dialog
const isDialogVisible = ref(false)
const dialogReviewResult = ref(true)
const dialogReviewReason = ref('')

// Page Data
const searchText = ref('')
const reportList = ref([])
const formattedReport = ref([])
const selectedReport = ref(undefined)
const reportObj = ref<Report>()
const reportCount = ref(0)
const selectedSort = ref(undefined);
const sortOptions = ref([
  { value: 'created', title: 'Recently Added'},
  { value: 'changed', title: 'Recently Changed'},
  { value: 'title', title: 'Title'}
]);

const tableItemRequestData = ref({
  rows: [],
  columns: [
    { name: 'Item Name', field_name: 'name', type: 'text' },
    { name: 'Kyat per unit', field_name: 'kyat',type: 'text'},
    { name: 'Count', field_name: 'count',type: 'text'},
    { name: 'Total Kyat', field_name: 'total',type: 'text'},
  ]
})

const topRowStyles = {
  background: '#EBF4FA',
  fontWeight: 'bold'
}

const listActionItems = ref([
  { title: 'Download Report List', value: 'download_reports' },
]);

const listActionReportItems = ref([
  { status: 'Unreviewed', title: 'Review Report', value: 'review_report' },
  { title: 'Download Report', value: 'download_report' },
]);

onMounted(async () => {
  selectedSort.value = sortOptions.value[0].value;
  isLoading.value = true; 
})

// watch(locale, () => {
//   isLoading.value = true;
// });

watch(
  () => [
    currentUser.value,
    school.value,
    schoolStatuses.value,
    reports.value,
    reportStatuses.value,
    reportTypes.value,
    buildings.value,
    buildingFacilities.value,
    buildingFacilityTypes.value,
  ],
  ([
    currentUser,
    school,
    schoolStatuses,
    reports,
    reportStatuses,
    reportTypes,
    buildings,
    buildingFacilities,
    buildingFacilityTypes,
  ]) => {
    if (currentUser
      && school
      && schoolStatuses
      && reports
      && reportStatuses
      && reportTypes
      && buildings
      && buildingFacilities
      && buildingFacilityTypes) {
      console.log('==== report page data ready =====')
      const bool = verifyTeacherSchoolPermission(currentUser, school, router);
      if (!bool) {
        router.push({ name: 'schools' })
      } else {
        isLoading.value = false;
        if (school) {
          formatSchoolData();
          formatListData();
          if (reportUuid && reportList.value.length) {
            const foundReport = _.find(reportList.value, (r)=> {
              return r.uuid === reportUuid;
            });
            selectReport(foundReport);
          } else if (!selectedReport.value && reportList.value.length) {
            selectReport(reportList.value[0]);
          }
        }
      }
    }
  }
);

function formatSchoolData() {
  const foundStatus = _.find(schoolStatuses.value, (ts) => {
    return ts.uuid === school.value.field_school_status;
  });
  school.value.schoolStatus = foundStatus ? foundStatus : undefined

  const reportStatusMapping = _.groupBy(reportStatuses.value, 'uuid');
  const reportTypeMapping = _.groupBy(reportTypes.value, 'uuid');

  formattedReport.value = _.map(reports.value, (d) => {
    d.type = d.field_report_type && reportTypeMapping[d.field_report_type].length ? reportTypeMapping[d.field_report_type][0].name : '';
    d.status = d.field_report_status && reportStatusMapping[d.field_report_status].length ? reportStatusMapping[d.field_report_status][0].name : '';
    return d;
  });
  // console.log('reports', formattedReport.value);
}

async function formatListData() {
  if (!searchText.value) {
    searchText.value = '';
  }
  const textSearch = searchText.value.toLowerCase();
  const mainList = _.cloneDeep(reports.value);
  let filteredReport = _.filter(mainList, (t) => {
    let textBool = true;
    if (textSearch) {
      textBool = t.title.toLowerCase().indexOf(textSearch) > -1;
    }
    return textBool;
  });

  // Sort by field
  let sortedData = [];
  if (selectedSort.value === 'created' || selectedSort.value === 'changed') {
    sortedData = _.orderBy(filteredReport, [selectedSort.value], ['desc']);
  } else {
    sortedData = _.sortBy(filteredReport, selectedSort.value);
  }

  reportList.value = sortedData;
  reportCount.value = sortedData.length;
}


async function selectReport(obj) {
  isLoading.value = true;
  const routeLocation = router.resolve({ name: 'schools-id-reports-id2?', params: {
    id: schoolUuid, id2: obj.uuid } });
  router.push(routeLocation);
  selectedReport.value = _.cloneDeep(obj);
  const tempFetchReport = await useReport(obj.uuid);
  const {data: tempFetchReportRequestItems } = await useReportRequestItems(obj.uuid);
  const reportStatusMapping = _.groupBy(reportStatuses.value, 'uuid');
  const reportTypeMapping = _.groupBy(reportTypes.value, 'uuid');
  const foundBuilding = _.find(buildings.value, (b) => { return b.uuid === tempFetchReport.field_building});

  tempFetchReport.type = tempFetchReport.field_report_type && reportTypeMapping[tempFetchReport.field_report_type].length ? reportTypeMapping[tempFetchReport.field_report_type][0].name : '';
  tempFetchReport.status = tempFetchReport.field_report_status && reportStatusMapping[tempFetchReport.field_report_status].length ? reportStatusMapping[tempFetchReport.field_report_status][0].name : '';
  tempFetchReport.building = undefined;

  // Create building tree data
  if (foundBuilding) {
    const buildingNodeName = `Building (${foundBuilding.field_name})`;
    const buildingNode: any = getTreeObject(foundBuilding.id,'building',buildingNodeName, 0);
    // Loop facility
    _.each(buildingFacilityTypes.value, (facilityType, index) => {
      const foundBuildingFacility = _.find(buildingFacilities.value, (bf) => {
        return bf.field_building_facility_type === facilityType.id &&
        bf.field_building === foundBuilding.id;
      })

      if (foundBuildingFacility && foundBuildingFacility.field_count > 0) {
        const bfName = `${facilityType.name} (${foundBuildingFacility.field_count})`
        const bfObj = getTreeObject(foundBuildingFacility.id, 'facility', bfName, foundBuildingFacility. index);
        buildingNode.children.push(bfObj);
      }
    });
    tempFetchReport.building = buildingNode;
  }

  // console.log('tempFetchReport', tempFetchReport);
  // console.log('tempFetchReportRequestItems', tempFetchReportRequestItems.value);

  const tempRows = [];
  let sum = 0;
  _.each(tempFetchReportRequestItems.value, (rri) => {
    const total = rri.field_cost * rri.field_count;
    const rowObj = {
      name: rri.field_name,
      kyat: rri.field_cost.toLocaleString(),
      count: rri.field_count,
      total: total.toLocaleString(),
    }
    sum += total;
    tempRows.push(rowObj);
  })

  if (tempFetchReportRequestItems.value.length) {
    const tempAllObj = {
      name: 'Total',
      kyat: '-',
      count: '-',
      total: sum.toLocaleString(),
      styles: topRowStyles
    };
    tempRows.unshift(tempAllObj);
  }

  tableItemRequestData.value.rows = tempRows;
  reportObj.value = { ...tempFetchReport };
  isLoading.value = false;
}

async function selectAction(actionItem) {
  if (actionItem.value === 'review_report') {
    isDialogVisible.value = true
  } else if (actionItem.value === 'download_reports') {
    const filename = `${school.value.field_name}_report_data`;
    csvDownload({
      data: reportList.value,
      filename: filename,
      delimiter: ','
    });
  } else if (actionItem.value === 'download_report') {
    const filename = `report_data_${reportObj.value.title}`;
    html2pdf(document.getElementById("report-body"), {
      // margin: 1,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      pagebreak: { mode: 'legacy'},
      filename: filename,
    });
  }
}

async function submitReview() {
  const currentUserUuid = currentUser.value?.uuid;
  const reportObjUuid = reportObj.value?.uuid;
  const schoolUuid = school.value?.uuid;
  const groupUuid = school.value?.group_uuid;
  if (currentUserUuid === undefined || reportObjUuid === undefined ||
    schoolUuid === undefined || groupUuid === undefined
  ) {
    console.log('submitReview(): master data not found',
      currentUserUuid, reportObjUuid, schoolUuid, groupUuid);
    alert(t('common.error'));
    return;
  }

  isDialogVisible.value = false
  isLoading.value = true;
  const statusFindId = dialogReviewResult.value === true ? 6102 : 6103;
  const foundStatusObj = _.find(reportStatuses.value, (d) => {
                            return d.id === statusFindId
                          });

  const reportUpdateObject: Attributes = {
    field_review_result: dialogReviewReason.value,
    field_review_date: getJsonApiDate(new Date()),
  };

  const relationData: Relationships = {
    field_report_status: {
      data: [{ type: 'taxonomy_term--report_status', id: foundStatusObj.uuid, }],
    },
    field_review_user: {
      data: [{ type: 'user--user', id: currentUserUuid, }],
    },
  };

  try {
    await updateById('node', 'report', reportObjUuid, reportUpdateObject, relationData);
    await insertNotification(groupUuid, schoolUuid, 'reviewed_report', reportObjUuid);
    location.reload();
  } catch (error: unknown) {
    console.log('onSubmit(): error', error)
    isLoading.value = false;
  }
}

definePage({
  meta: {
    breadcrumb: 'Plan',
  },
})
</script>

<template>
  <div>
    <SchoolDetailNavigation :school="school" />
  </div>
  <VCard height="100%" width="100%">
    <VLayout>
      <VMain class="report-page-container">
        <div class="pa-0 ma-0 w-100 h-100">
          <div v-if="isLoading" style="height: 600px;">
            <LoadingSpinner />
          </div>
          <VRow class='ma-0' v-if="!isLoading && school">
            <VCol cols="4" class="pt-2">
              <div>
                <h2>Plan List</h2>
              </div>

              <div class="ma-5">
                <div class="mt-3 mb-0 d-flex align-start">
                  <VTextField
                    v-model="searchText"
                    clearable
                    label="Search"
                    prepend-inner-icon="tabler-search"
                    variant="outlined"
                    flat
                    hide-details
                    @update:modelValue="formatListData()"
                  />
                  <VBtn
                    @click="formatListData()"
                    class="ms-2">Filter</VBtn>
                </div>
                <div class="mt-3 d-flex align-start">
                  <div>
                    <VSelect
                      v-model="selectedSort"
                      :items="sortOptions"
                      @update:model-value="formatListData()"
                    />
                  </div>
                  <div class="ms-2 mt-2">Total {{reportCount}} reports</div>
                </div>
              </div>

              <div
                class="ma-5 mt-0 action-button-container">
                <v-btn
                  color="primary">
                  Action
                  <v-menu activator="parent">
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in listActionItems"
                        :key="index"
                        :value="index">
                        <v-list-item-title
                          @click="selectAction(item)">
                          <span v-if="item.value">{{ item.title }}</span>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-btn>
              </div>

              <VCard class="ma-5">
                <VList lines="two" class="report-list pa-0">
                  <VListItem
                      @click="selectReport(listItem)"
                      :class="selectedReport && selectedReport.id === listItem.id ? 'selected' : ''"
                      class="report-item"
                      v-for="listItem in reportList"
                      :key="listItem.id"
                      :subtitle="listItem.status"
                      :title="listItem.title">
                      <div
                        class="item-icon-end">
                        <VIcon
                          size="20"
                          icon="tabler-arrow-right"
                        />
                      </div>
                    </VListItem>
                  </VList>
              </VCard>

            </VCol>
            <VCol cols="8" class="pt-2">
              <div v-if="reportObj">
                <div class="ma-5 mt-0 action-button-container">
                  <v-btn
                    color="primary">
                    Action
                    <v-menu activator="parent">
                      <v-list>
                        <v-list-item
                          v-for="(item, index) in listActionReportItems"
                          :class="reportObj
                          && item.status !== undefined
                          && (reportObj.status !== item.status) ? 'hidden' : ''"
                          :key="index"
                          :value="index">
                          <v-list-item-title
                            @click="selectAction(item)">
                            <span>{{ item.title }}</span>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-btn>
                </div>

                <div id="report-body" class="report-body">
                  <VCard class="ma-5">
                    <div class="information-box pb-3">
                      <h3>Review</h3>
                    </div>
                    <div class="information-box pt-0 pb-3">
                      <VRow class='ma-0 mb-2'>
                        <VCol cols="6" class="pa-0">
                          <div class="sub-title-text">Reviewed Result</div>
                          <div class="">{{reportObj.status}}</div>
                        </VCol>
                        <VCol cols="6" class="pa-0">
                          <div class="sub-title-text">Review Reason</div>
                          <div class="">{{reportObj.field_review_result}}</div>
                        </VCol>
                      </VRow>
                    </div>
                  </VCard>

                  <VCard class="ma-5">
                    <div class="information-box pb-3">
                      <h3>Report Information</h3>
                    </div>
                    <div class="information-box pt-0 pb-3">
                      <VRow class="ma-0 mb-2 gap-1">
                        <VCol cols="12" class="pa-0 pb-2">
                          <div class="sub-title-text">Type of Plan</div>
                          <div class="">{{reportObj.type}}</div>
                        </VCol>
                        <VCol cols="12" class="pa-0 pb-2">
                          <div class="sub-title-text">Title of Plan</div>
                          <div class="">{{reportObj.title}}</div>
                        </VCol>
                        <VCol v-if="reportObj.building" cols="12" class="pa-0 pb-2">
                          <div class="sub-title-text">Refer to Facility</div>
                          <div>
                            <SimpleTree :data="reportObj.building"/>
                          </div>
                        </VCol>
                        <VCol cols="12" class="pa-0 pb-2">
                          <div class="sub-title-text">Objectives (briefly outline the specific goals your school/learning center wants to achieve)</div>
                          <div class="">{{reportObj.field_report_objective}}</div>
                        </VCol>
                        <VCol cols="12" class="pa-0 pb-2">
                          <div class="sub-title-text">Areas for Development Identified (briefly describe the key areas that your school/learning center needs to focus on)</div>
                          <div class="">{{reportObj.field_report_area_dev_ind}}</div>
                        </VCol>
                        <VCol cols="12" class="pa-0 pb-2">
                          <div class="sub-title-text">Targets (please state the measurable outcomes or milestones that your school/learning center aims to achieve for e.g. provide school feeding support to 50 students within 2 years)</div>
                          <div class="">{{reportObj.field_report_target}}</div>
                        </VCol>
                        <VCol cols="12" class="pa-0 pb-2">
                          <div class="sub-title-text">Progress (please provide an update on progress and/or achievements made against objectives and areas for development identified)</div>
                          <div class="">{{reportObj.field_report_progress || '-' }}</div>
                        </VCol>
                        <VCol cols="12" class="pa-0 pb-2">
                          <div class="sub-title-text">Remarks (Issue, Actions and Targets)</div>
                          <div class="">{{reportObj.field_memo || '-'}}</div>
                        </VCol>
                      </VRow>
                    </div>
                  </VCard>

                  <div class="html2pdf__page-break" />

                  <VCard class="ma-5">
                    <div class="information-box pb-3">
                      <h3>Assistance Required</h3>
                    </div>
                    <div class="information-box pt-0 pb-3">
                      <CustomTable :data="tableItemRequestData"/>
                    </div>
                  </VCard>

                  <VCard class="ma-5">
                    <div class="information-box pb-3">
                      <h3>Supporting Information</h3>
                      <div class="mb-2">
                        total {{reportObj.field_photo_gallery.length}} photos
                      </div>
                      <div class="report-photo-box">
                        <div
                          v-for="(item, index) in reportObj.field_photo_gallery"
                          class="image-item">
                          <a :href="item.uri" target="_blank">
                            <img class="image-view" :src="item.thumbnail_uri">
                          </a>
                        </div>
                      </div>

                      <div v-if="reportObj.field_report_docs && reportObj.field_report_docs.length">
                        <div class="mb-2">
                          total {{reportObj.field_report_docs.length}} files
                        </div>

                        <div
                          v-for="(item, index) in reportObj.field_report_docs"
                          class="">
                          <a :href="item.uri" target="_blank">
                            {{item.filename}}
                          </a>
                        </div>
                      </div>
                    </div>
                  </VCard>

                  <VCard class="ma-5">
                    <div class="information-box pb-3">
                      <h3>Time Stamp</h3>

                      <VRow class='ma-0 mb-2'>
                        <VCol cols="6" class="pa-0">
                          <div class="sub-title-text">Requested On</div>
                          <div class="">{{reportObj.created ? getFigmaDate(reportObj.created) : ''}}</div>
                        </VCol>
                        <VCol cols="6" class="pa-0">
                          <div class="sub-title-text">Requested By</div>
                          <div class="">{{reportObj.reportUser ? reportObj.reportUser.name : ''}}</div>
                        </VCol>
                      </VRow>

                      <VRow class='ma-0 mb-2'>
                        <VCol cols="6" class="pa-0">
                          <div class="sub-title-text">Reviewed On</div>
                          <div class="">{{reportObj.field_review_date ? getFigmaDate(reportObj.field_review_date) : ''}}</div>
                        </VCol>
                        <VCol cols="6" class="pa-0">
                          <div class="sub-title-text">Reviewed By</div>
                          <div class="">{{reportObj.reviewUser ? reportObj.reviewUser.name : ''}}</div>
                        </VCol>
                      </VRow>
                    </div>
                  </VCard>
                </div>
              </div>
            </VCol>
          </VRow>

          <VDialog
            v-model="isDialogVisible"
            width="auto">
            <v-card
              width="500"
              title="Review Report">
              <div>
                <div class="review-school-container information-box">
                  <VRow class='ma-0 mb-2'>
                    <VCol cols="12" class="pa-0 mb-3">
                      <div class="sub-title-text">Title Of Plan</div>
                      <div class="">{{reportObj.title}}</div>
                    </VCol>
                    <VCol v-if="reportObj.building" cols="12" class="pa-0 mb-3">
                      <div class="sub-title-text">Refer to Facility</div>
                      <div>
                        <SimpleTree :data="reportObj.building"/>
                      </div>
                    </VCol>
                    <VCol cols="12" class="pa-0 mb-3">
                      <div class="sub-title-text">
                        Reviewed Result
                        <span class="req-indicator"> *</span>
                      </div>
                      <v-radio-group v-model="dialogReviewResult">
                        <v-radio label="Approve" :value="true"></v-radio>
                        <v-radio label="Reject" :value="false"></v-radio>
                      </v-radio-group>
                    </VCol>
                    <VCol cols="12" class="pa-0 mb-3">
                      <div class="sub-title-text">
                        Review Reason
                        <span class="req-indicator"> *</span>
                      </div>
                      <v-textarea
                        v-model="dialogReviewReason"
                        clearable
                        label=""
                        variant="outlined"></v-textarea>
                    </VCol>
                  </VRow>  
                </div>

              </div>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  text="Discard"
                  variant="plain"
                  @click="isDialogVisible = false"
                ></v-btn>

                <v-btn
                  :disabled="!dialogReviewReason.length"
                  color="primary"
                  text="Submit"
                  variant="tonal"
                  @click="submitReview();"
                ></v-btn>
              </v-card-actions>
            </v-card>
          </VDialog>
        </div>
      </VMain>
    </VLayout>
  </VCard>
</template>

<style lang="scss">
  .report-page-container {
  }
  .report-list {
    max-height: 80vh;
    .report-item {
      border-bottom: 1px solid lightgrey;
      cursor: pointer;
      &.selected {
        background: #F1F4F6;
      }
    }
    .item-icon-end {
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
  .report-photo-box {
    .image-item {
      display: inline-block;
      margin: 5px;
    }
    .image-view {
      width: 100px;
      height: 100px;
    }
  }
</style>
