import { createFetch } from '@vueuse/core'
import { destr } from 'destr'

import {API_BASE_URL} from '@/env'

import {getCSRFTokenHeader} from '@/utils/fetch'
import {getApiHeader} from '@/utils/drupal'

export const useDrupalApi = createFetch({
  baseUrl: API_BASE_URL,
  fetchOptions: {
    headers: getApiHeader(),
    credentials: 'same-origin',
  },
  options: {
    refetch: true,
    async beforeFetch({ options }) {
      if (options.headers && (options.headers as any)['X-CSRF-Token']) {
        return {options}
      }
      const [csrfTokenHeaderName, csrfTokenHeaderValue] = await getCSRFTokenHeader()
      // console.log('csrf token', csrfTokenHeaderValue)

      options.headers = {
        ...options.headers,
        [csrfTokenHeaderName]: csrfTokenHeaderValue,
      }

      return { options }
    },
    afterFetch(ctx) {
      const { data, response } = ctx

      // Parse data if it's JSON
      let parsedData = null
      try {
        parsedData = destr(data)
      } catch (error: unknown) {
        console.log('useApi(): error', error)
      }

      return { data: parsedData, response }
    },
  },
})
