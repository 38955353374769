import { API_BASE_URL } from '@/env';
import {useDrupalApi} from '@/composables/useDrupalApi';

import { FetchOptions, fetchWithCSRFToken, getCSRFTokenHeader } from './fetch';

export type DrupalType = 'node' | 'paragraph' | 'taxonomy_term' | 'file';

export type Attributes = {
  title?: string;
  [x: string]: any;
};
export type Relationships= Record<string, RelationshipData>;
export type RelationshipData = {
  data: Array<RelationshipItem>;
}
export interface RelationshipItem {
  type: string;
  id: string;
  data?: {
    meta?: {
      alt?: string
      title?: string
    }
  }
}

export function getDrupalPath(path: string): string {
  return `${API_BASE_URL}${path}`;
}

export function getApiUrl(type: DrupalType, typeName: string, id?: string): string {
  const baseUrl = getDrupalPath(`/jsonapi/${type}/${typeName}`);
  if (id === undefined) return baseUrl;
  return `${baseUrl}/${id}`
}

export function getJsonApiHeader(): Headers {
  const headers = new Headers({
    'Content-Type': 'application/vnd.api+json',
    Accept: 'application/vnd.api+json',
  });
  return headers;
}

export function getApiHeader(): Headers {
  const headers = new Headers({
    'Content-Type': 'application/json'
  })
  return headers
}

export type ApiObj = {
  data: {
    type: string,
    attributes: Attributes,
    relationships?: Relationships,
    id?: string
  }
}
export function getApiObj(
  type: string,
  attributes: Attributes,
  id?: string,
  relationships?: Relationships
): ApiObj {
  const apiObj = {
    data: { type, attributes, relationships, id }
  };
  return apiObj;
}

export function getSaveObject(type: string, attributes: Attributes, id?: any, relations?: Relationships): ApiObj {
  const _attributes: Record<string, string | number | boolean > = {};
  for (const [key, value] of Object.entries(attributes)) {
    if (key === 'field_jsonb') {
      _attributes[key] = JSON.stringify(value);
      continue;
    }
    _attributes[key] = value;
  }
  // if (attributes.title === undefined) {
  //   attributes.title = '';
  // }
  return getApiObj(type, _attributes, id, relations);
}

export async function fetchDrupal<T>(url: string, options: FetchOptions): Promise<T> {
  const response = await fetchWithCSRFToken(url, options);
  // console.log('fetchDrupal(): url', url, options)
  if (!response.ok) {
    // const error  = new Error('An error occurred while fetching to drupal.')
    const errorInfo = await response.json()
    // console.log('fetchDrupal error:', errorInfo, response.status)
    throw errorInfo
  }
  const responseContentType = response.headers.get('content-type')
  return responseContentType?.indexOf('json') ? response.json() : response.text()
}

export type InsertResult = {
  uuid: string
  id: number
}
export async function insert(
  type: DrupalType,
  typeName: string,
  attributes: Attributes,
  relationships?: Relationships,
  groupUuid?: string,
): Promise<InsertResult> {
  const url = getApiUrl(type, typeName);
  const data = getSaveObject(`${type}--${typeName}`, attributes, undefined, relationships);
  const options: FetchOptions  = {
    method: 'POST',
    // credentials: 'same-origin',
    headers: getJsonApiHeader(),
    body: JSON.stringify(data)
  };

  const result = await fetchDrupal<Record<string, any>>(url, options);
  const uuid = result.data.id;
  if (groupUuid !== undefined) {
    const addNodeToGroupResult = await addNodeToGroup(groupUuid, uuid)
    if (addNodeToGroupResult === false) {
      throw new Error('Add Node to Group failed')
    }
  };
  return {
    uuid: result.data.id,
    id: type === 'taxonomy_term'
      ? result.data.attributes.drupal_internal__tid
      : type === 'paragraph' ? result.data.attributes.drupal_internal__pid : result.data.attributes.drupal_internal__nid,
  };
}

export type InsertRelationsResult = Record<string, any>
export async function insertRelationsById(
  type: DrupalType,
  typeName: string,
  id: string,
  fieldname: string,
  relationshipData: RelationshipData, 
): Promise<any> {
  const url = `${getApiUrl(type, typeName, id)}/relationships/${fieldname}`
  const options = {
    method: 'POST',
    // credentials: 'same-origin',
    headers: getJsonApiHeader(),
    body: JSON.stringify(relationshipData)
  }

  return fetchDrupal<InsertRelationsResult>(url, options)
}

export type UpdateResult = Record<string, any>
export async function updateById(
  type: DrupalType,
  typeName: string,
  id: string,
  attributes: Attributes,
  relationships?: Relationships,
): Promise<UpdateResult> {
  const url = getApiUrl(type, typeName, id)
  const data = getSaveObject(`${type}--${typeName}`, attributes, id, relationships)
  const options: FetchOptions = {
    method: 'PATCH',
    // credentials: 'same-origin',
    headers: getJsonApiHeader(),
    body: JSON.stringify(data)
  }

  return fetchDrupal<UpdateResult>(url, options)
}

export async function deleteById(type: DrupalType, typeName: string, id: string): Promise<void> {
  const url = getApiUrl(type, typeName, id)
  const options: FetchOptions = {
    method: 'DELETE',
    // credentials: 'same-origin',
    headers: getJsonApiHeader()
  };

  const response = await fetchWithCSRFToken(url, options)
  if (!response.ok) {
    const error  = new Error('An error occurred while fetching to drupal.')
    const errorInfo = await response.json()
    console.log('deleteById error:', errorInfo, response.status)
    throw error
  }
}

export async function getData<T>(path: string, fetchOptions?: FetchOptions): Promise<T> {
  const url = `${API_BASE_URL}/${path}`
  const _fetchOptions = { method: 'GET', headers: getApiHeader(), ...fetchOptions }
  return fetchDrupal<T>(url, _fetchOptions)
}

export async function addNodeToGroup(groupUuid: string, nodeUuid: string): Promise<boolean> {
  const url = `${API_BASE_URL}/api/v1/group/add-node`;
  const data = {
    group_uuid: groupUuid, node_uuid: nodeUuid,
  };
  const options: FetchOptions  = {
    method: 'POST',
    headers: getApiHeader(),
    body: JSON.stringify(data),
  };

  const result = await fetchDrupal<{status: boolean}>(url, options);
  return result.status;
}

export async function addUserToGroup(groupUuid: string,
  userUuid: string, 
): Promise<boolean> {
  const url = `${API_BASE_URL}/api/v1/group/add-user`
  const data = {
    group_uuid: groupUuid, user_uuid: userUuid,
  }
  const options: FetchOptions  = {
    method: 'POST',
    headers: getApiHeader(),
    body: JSON.stringify(data)
  }

  const result = await fetchDrupal<{status: boolean}>(url, options)
  return result.status
}

export async function updateUserRole( userUuid: string, removeRole: string, addRole: string): Promise<boolean> {
  const url = `${API_BASE_URL}/api/v1/update-user-role`
  const data = {
    uuid: userUuid, role_to_remove: removeRole, role_to_add: addRole
  }
  const options: FetchOptions  = {
    method: 'POST',
    headers: getApiHeader(),
    body: JSON.stringify(data)
  }

  const result = await fetchDrupal<{status: boolean}>(url, options)
  return result.status
}

export async function callCustomAPI(
  apiType: string,
  dataObj: any
): Promise<InsertResult> {
  const url = `${API_BASE_URL}/api/v1/${apiType}`;

  const options: FetchOptions  = {
    method: 'POST',
    // credentials: 'same-origin',
    headers: getApiHeader(),
    body: JSON.stringify(dataObj)
  }
  const result = await fetchDrupal<Record<string, any>>(url, options)
  return result.data;
}

export type UploadFileResult = {
  uuid: string
  id: number
  filename: string
}
export async function uploadFile(
  type: DrupalType,
  typeName: string,
  fieldName: string,
  fileName: string,
  file: File,
): Promise<UploadFileResult> {
  try {
    const url = `${getApiUrl(type, typeName)}/${fieldName}`;
    const headers = new Headers({
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/octet-stream',
      'Content-Disposition': `file; filename="${fileName}"`
    });
    const result = await fetchDrupal<Record<string, any>>(url, {
      method: 'POST',
      headers,
      body: file,
    });
    return {
      uuid: result.data.id,
      id: result.data.attributes.drupal_internal__fid,
      filename: result.data.attributes.filename,
    };
  } catch (error: unknown) {
    console.log('UploadImage2 failed', error);
    throw new Error('UploadImage2 failed');
  }
}
