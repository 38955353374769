<script setup lang="ts">
import { useIntervalFn } from '@vueuse/core';

import { useI18n } from 'vue-i18n';

import { PerfectScrollbar } from 'vue3-perfect-scrollbar';

import ChatForm from '@/components/Chat/ChatForm.vue';
import type { OnSendMessageData } from '@/components/Chat/ChatForm.vue';
import ChatLog from '@/components/Chat/ChatLog.vue';
import type { Message, MessageImage, MessageText } from '@/components/Chat/ChatLog.vue';
import { useChats } from '@/composables/useChats';
import { useChatSchools, getChatSchoolsPath } from '@/composables/useSchool';
import type { ChatSchool } from '@/composables/useSchool';
import { useChatTypes } from '@/composables/useTaxonomy';
import type { ChatTypeIdType } from '@/composables/useTaxonomy';
import type { Chat } from '@/models/nodes/Chat';
import { Langcode } from '@/plugins/i18n';
import { insert, uploadFile } from '@/utils/drupal';
import type { RelationshipData, Relationships } from '@/utils/drupal';
import { insertNotification } from '@/utils/notification';

const { locale, t } = useI18n();
const router = useRouter();
const route = useRoute();
const params = route.params as Record<string, string>;

const refPs = ref();
const isLoading = ref<boolean>(true);
const refChatLog = ref<typeof ChatLog>();
const searchSchool = ref<string>('');
const schoolsPath = ref<string>(getChatSchoolsPath(locale.value as Langcode));
const selectedSchool = ref<ChatSchool | null>(null);
const messages = ref<Message[]>([]);
const newChat = ref(false);
const { data: currentUser } = await useCurrentUser2();
const { data: schools } = await useChatSchools(schoolsPath);
const { data: chatTypes } = useChatTypes();

const { isActive, pause, resume } = useIntervalFn(async () => {
  console.log('fetching');
  if (selectedSchool.value === null) return;
  await setMessages(selectedSchool.value);
}, 3000, { immediate: false });

watch(route, async (route) => {
  const params = route.params as Record<string, string>;
  if (params.id === '') return;
  setSelectedSchool(params.id);
  if (selectedSchool.value === null) return;
  intervalSetMessages(selectedSchool.value);
});

onMounted(async () => {
  setSelectedSchool(params.id);
  isLoading.value = false;
  if (selectedSchool.value === null) {
    router.push({ path: '/chat' });
    return;
  }
  intervalSetMessages(selectedSchool.value);
});

onUnmounted(() => {
  pause();
});

function setSelectedSchool(schoolUuid?: string): void {
  const school = schoolUuid ? schools.value?.find(e => e.uuid === schoolUuid) : undefined;
  selectedSchool.value = school !== undefined ? school : null;
}

async function intervalSetMessages(school: ChatSchool): Promise<void> {
  await setMessages(school);
  if (!isActive.value) { resume(); }
  setTimeout(() => {
    if (refChatLog.value === undefined) return;
    refChatLog.value.scrollToBottom();
  }, 500); // Waiting for ChatLog rendering
}

async function setMessages(school: ChatSchool): Promise<void> {
  const { data: chats } = await useChats(school.uuid);
  if (chats.value === null) {
    alert(t('common.error'));
    return;
  }
  const _messages: Message[] = [];
  for (const chat of chats.value) {
    const type = chat.user_roles.includes('teacher') ? 'received' : 'sent';
    if (chat.body) {
      const message: MessageText = {
        dataType: 'text',
        type,
        id: chat.uuid,
        text: chat.body,
        createdAt: chat.created,
      };
      _messages.push(message);
      continue;
    }
    if (chat.field_chat_files?.length) {
      const message: MessageImage = {
        dataType: 'image',
        type,
        id: chat.uuid,
        uri: chat.field_chat_files[0],
        createdAt: chat.created,
      }
      _messages.push(message)
    }
  }
  messages.value = { ..._messages };
  if (newChat.value) {
    newChat.value = false;
    setTimeout(() => {
      if (refChatLog.value === undefined) return;
      refChatLog.value.scrollToBottom();
    }, 0);
  }
}

function handleFormFilterSubmit(): void {
  schoolsPath.value = `${getChatSchoolsPath(locale.value as Langcode)}&name=${searchSchool.value}`;
  const containerEl = refPs.value.ps.element as HTMLElement;
  containerEl.scrollTop = 0;
  // setTimeout(() => {
  //   refPs.value.ps.update();
  // }, 0); // Waiting for rendering
}

async function handleSchoolClick(school: ChatSchool): Promise<void> {
  if (selectedSchool.value !== null && selectedSchool.value.uuid === school.uuid) {
    selectedSchool.value = null;
    router.push({ path: '/chat' });
    pause();
    return;
  }
  selectedSchool.value = JSON.parse(JSON.stringify(school));
  router.push({ params: { id: school.uuid } });
}

async function handleOnSendMessage(data: OnSendMessageData): Promise<void> {
  if (
    chatTypes.value === null || selectedSchool.value === null ||
    currentUser.value === null || schools.value === null
  ) {
    console.log('handleOnSendMessage(): Master data not found',
      chatTypes.value, selectedSchool.value, currentUser.value, schools.value);
    alert(t('common.error'));
    return;
  }

  const groupUuid = selectedSchool.value.group_uuid;
  if (!groupUuid) {
    console.log('handleOnSendMessage(): groupUuid not found');
    alert(t('common.error') + 
      '. This might be old data that does not compatible with new structure, please use another data');
    return;
  }

  const chatTypeIdType: ChatTypeIdType =  typeof data.value === 'string'
    ? 'text' : 'image';
  const chatTypeId = getChatTypeId(chatTypeIdType);
  const chatType = chatTypes.value.find(chatType => {
    return chatTypeId  === chatType.id;
  });
  if (chatType === undefined) {
    console.log('handleOnSendMessage(): chatTypes not found');
    alert(t('common.error'));
    return;
  }

  try {
    const now = new Date();
    const chatData: Partial<Chat> = {
      title: `${selectedSchool.value.uuid}_${currentUser.value.uuid}_${now.getTime()}`,
    };
    if (chatTypeIdType === 'text') {
      chatData.body = data.value as string;
    }
    const chatRelationship: Relationships = {
      field_school: {
        data: [{type: 'node--school', id: selectedSchool.value.uuid}],
      },
      field_chat_type: {
        data: [{type: 'taxonomy_term--chat_type', id: chatType.uuid}],
      },
    };
    const insertChatResult = await insert('node', 'chat', chatData,
      chatRelationship, groupUuid);
    if (chatTypeIdType === 'image') {
      const file = data.value as File;
      const fileName = file.name;
      const res = await uploadFile('node', 'chat', 'field_chat_files', fileName, file);
      const fileRelationData: RelationshipData = {data: []};
      fileRelationData.data.push({
        type: 'file--file',
        id: res.uuid,
        data: {meta: {alt: fileName}},
      });
      await insertRelationsById('node', 'chat', insertChatResult.uuid,
        'field_chat_files', fileRelationData);
    }
    await insertNotification(groupUuid, selectedSchool.value.uuid,
      'chat_unesco', insertChatResult.uuid);
    newChat.value = true;
  } catch (error: unknown) {
    console.log('handleOnSendMessage(): error', error);
    alert(t('common.error'));
  }
}

definePage({
  meta: {
    breadcrumb: 'Chat',
  },
})
</script>

<template>
  <VCard height="100%" width="100%">
    <VLayout class="chat-page-layout" full-height>
      <VMain class="chat-page-content pa-5">
        <VRow>
          <VCol>
            <h1>Chat</h1>
          </VCol>
        </VRow>
        <LoadingSpinner v-if="isLoading" />
        <VRow
          v-else
          :style="{
            height: 'calc(100vh - 300px)',
          }"
        >
          <VCol cols="4" class="h-100">
            <VForm
              class="d-flex gap-3"
              @submit.prevent="handleFormFilterSubmit()"
            >
              <VTextField
                v-model="searchSchool"
              >
                <template #prepend-inner>
                  <div class="d-flex align-center text-high-emphasis me-1">
                    <VIcon
                      size="24"
                      icon="tabler-search"
                    />
                  </div>
                </template>
              </VTextField>
              <VBtn
                type="submit"
                variant="outlined"
              >Filter</VBtn>
            </VForm>
            <PerfectScrollbar
              ref="refPs"
              tag="ul"
              :options="{ wheelPropagation: false }"
              :style="{
                height: '100%',
              }"
            >
              <VList lines="two" class="school-list">
                <VListItem
                  v-for="school in schools"
                  :key="school.uuid"
                  :class="[{
                    'active': (selectedSchool !== null && selectedSchool.uuid === school.uuid)
                  }]"
                  :title="school.field_name"
                  :subtitle="school.chat ? getFigmaDate(new Date(school.chat.changed * 1000)) : null"
                  @click="handleSchoolClick(school)"
                >
                  <template v-slot:title="{ title }">
                    <h3 class="text-wrap">
                      {{ title }}
                      <template v-if="school.chat" class="text-subtitle-2">
                        ({{ school.field_abbreviation }})
                      </template>
                    </h3>
                  </template>
                  <template v-slot:append>
                    <VIcon icon="tabler-arrow-right" />
                  </template>
                </VListItem>
              </VList>
            </PerfectScrollbar>
          </VCol>
          <VCol v-if="selectedSchool !== null"
            cols="8"
            class="h-100 d-flex flex-column gap-3"
          >
            <ChatLog ref="refChatLog" class="flex-grow-1" :messages="messages" />
            <ChatForm @onSendMessage="handleOnSendMessage"/>
          </VCol>
        </VRow>
      </VMain>
    </VLayout>
  </VCard>
</template>

<style lang="scss" scoped>
.active {
  background: #F1F4F6;
}
</style>
