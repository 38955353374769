<script setup lang="ts">
import _ from 'lodash'
import csvDownload from 'json-to-csv-export'
import { useI18n } from 'vue-i18n'

import { API_BASE_URL } from '@/env';
import { insertNotification } from '@/utils/notification';

const router = useRouter()
const route = useRoute()
const { t, locale } = useI18n();

const params = route.params as Record<string, string>;
const schoolUuid = ref<string>(params.id ? params.id : '');

const { data: currentUser } = useCurrentUser2();
const { data: school } = useSchool(schoolUuid.value)
const images = ref(undefined)
const filteredImages = ref([])

const imageCount = ref(0)
const isLoading = ref(false);
const selectedTab = ref<string>('all')
const selectedSort = ref(undefined);

const sortOptions = ref([
  { value: 'all', title: 'All'},
]);

const tabItems = ref([
  { name: 'All', value: 'all' },
  { name: 'Attendance', value: 'daily_log' },
  { name: 'Plan', value: 'report' },
  { name: 'Chat', value: 'chat' },
]);

onMounted(async () => {
	selectedSort.value = sortOptions.value[0].value;
  isLoading.value = true; 
})

watch(
  () => [
    currentUser.value,
    school.value,
  ],
  ([
    currentUser,
    school,
  ]) => {
    if (currentUser
      && school) {
      console.log('==== gallery page data ready =====')
      const bool = verifyTeacherSchoolPermission(currentUser, school, router);
      if (!bool) {
        router.push({ name: 'schools' })
      } else {
        if (school) {
          // Start format data
          initialPage();
        }
      }
    }
  }
);

async function initialPage() {
	images.value = await useSchoolImages(schoolUuid.value)
	// console.log('images', images.value);
	createFilterDropdown();
	formatListData();
	isLoading.value = false;
}

function createFilterDropdown() :void {
  const allImages = images.value;
  const mapDateCate = _.map(allImages, 'date_cate');
  const uniqDateCate = _.uniq(mapDateCate);

  const result: any = [{ title: 'All', value: 'all'}];
  _.each(uniqDateCate, (c) => {
    const obj = {
      title: c,
      value: c
    };
    result.push(obj);
  });
  sortOptions.value = result;
  selectedSort.value = sortOptions.value[0].value;
}

async function formatListData() {
	if (!images.value || !images.value.length) {
		filteredImages.value = [];
	} else {
		const imageType = selectedTab.value;
		const sortValue = selectedSort.value;
		let rawImageList = _.cloneDeep(images.value);
    if (imageType !== 'all') {
      rawImageList = _.filter(rawImageList, (d) => {
        return d.type === imageType;
      });
    }
    if (sortValue !== 'all') {
    	rawImageList = _.filter(rawImageList, (d) => {
	      return d.date_cate === sortValue;
	    });
    }
    filteredImages.value = rawImageList;
	}
}

function setTab() {
}

definePage({
  meta: {
    breadcrumb: 'Gallery',
  },
})

</script>

<template>
  <div>
    <SchoolDetailNavigation :school="school" />
  </div>
  <VCard height="100%" width="100%">
    <VLayout>
      <VMain class="gallery-page-container">
        <div class="pa-0 ma-0 w-100 h-100">
          <div v-if="isLoading" style="height: 600px;">
            <LoadingSpinner />
          </div>

          <VRow class='ma-0' v-if="!isLoading && school">
          	<VCol cols="12" class="pt-2">
              <div>
                <h2>Gallery</h2>
              </div>

              <div class="ma-5">
              	<div class="mt-3 mb-0 d-flex align-start">
							    <VTabs
							      class="button-group-container"
							      v-model="selectedTab"
							      @click="formatListData()">
							      <VTab class="pa-0 ma-0" style="min-width:0px; display: none;" />
							      <VTab
							        v-for="item in tabItems"
							        :value="item.value">
							        {{ $t(item.name) }}
							      </VTab>
							    </VTabs>
              	</div>

              	<div class="mt-3 mb-0 d-flex align-start">
                  <div>
                    <VSelect
                      v-model="selectedSort"
                      :items="sortOptions"
                      @update:model-value="formatListData()"
                    />
                  </div>
                  <div class="ms-2 mt-2">Total {{filteredImages.length}} photos</div>
              	</div>

	              <div class="mt-3 mb-0 d-flex align-start">
	                <div class="photo-box">
	                  <div
	                    v-for="(item, index) in filteredImages"
	                    class="image-item">
	                    <a :href="item.uri" target="_blank">
	                      <img class="image-view" :src="item.thumbnail_uri">
	                    </a>
	                  </div>
	                </div>
	              </div>
              </div>
          	</VCol>
          </VRow>
        </div>
      </VMain>
    </VLayout>
  </VCard>
</template>


<style lang="scss">
  .gallery-page-container {
	  .photo-box {
	    .image-item {
	      display: inline-block;
	      margin: 5px;
	      width: 100px;
	      height: 100px;
	    }
	    .image-view {
	      width: 100%;
	      height: 100%;
	      object-fit: cover;
	    }
	  }
  }
</style>