import _definePage_default_0 from '/Users/hanwin/Documents/ThinkBlueData/docker-classmap/app-backend/src/pages/[...error].vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/Users/hanwin/Documents/ThinkBlueData/docker-classmap/app-backend/src/pages/chat.[[id]].vue?definePage&vue'
import _definePage_default_3 from '/Users/hanwin/Documents/ThinkBlueData/docker-classmap/app-backend/src/pages/coming-soon.vue?definePage&vue'
import _definePage_default_4 from '/Users/hanwin/Documents/ThinkBlueData/docker-classmap/app-backend/src/pages/login.vue?definePage&vue'
import _definePage_default_5 from '/Users/hanwin/Documents/ThinkBlueData/docker-classmap/app-backend/src/pages/pending-task.vue?definePage&vue'
import _definePage_default_6 from '/Users/hanwin/Documents/ThinkBlueData/docker-classmap/app-backend/src/pages/register.vue?definePage&vue'
import _definePage_default_7 from '/Users/hanwin/Documents/ThinkBlueData/docker-classmap/app-backend/src/pages/schools.vue?definePage&vue'
import _definePage_default_8 from '/Users/hanwin/Documents/ThinkBlueData/docker-classmap/app-backend/src/pages/schools.[id].vue?definePage&vue'
import _definePage_default_9 from '/Users/hanwin/Documents/ThinkBlueData/docker-classmap/app-backend/src/pages/schools.[id].daily-logs.[[id2]].vue?definePage&vue'
import _definePage_default_10 from '/Users/hanwin/Documents/ThinkBlueData/docker-classmap/app-backend/src/pages/schools.[id].gallery.vue?definePage&vue'
import _definePage_default_11 from '/Users/hanwin/Documents/ThinkBlueData/docker-classmap/app-backend/src/pages/schools.[id].infrastructure.vue?definePage&vue'
import _definePage_default_12 from '/Users/hanwin/Documents/ThinkBlueData/docker-classmap/app-backend/src/pages/schools.[id].reports.[[id2]].vue?definePage&vue'
import _definePage_default_13 from '/Users/hanwin/Documents/ThinkBlueData/docker-classmap/app-backend/src/pages/schools.[id].student.vue?definePage&vue'
import _definePage_default_14 from '/Users/hanwin/Documents/ThinkBlueData/docker-classmap/app-backend/src/pages/schools.[id].teachers.[[id2]].vue?definePage&vue'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/:error(.*)',
    name: '$error',
    component: () => import('/Users/hanwin/Documents/ThinkBlueData/docker-classmap/app-backend/src/pages/[...error].vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  _mergeRouteRecord(
  {
    path: '/chat/:id?',
    name: 'chat-id?',
    component: () => import('/Users/hanwin/Documents/ThinkBlueData/docker-classmap/app-backend/src/pages/chat.[[id]].vue'),
    /* no children */
  },
  _definePage_default_2
  ),
  _mergeRouteRecord(
  {
    path: '/coming-soon',
    name: 'coming-soon',
    component: () => import('/Users/hanwin/Documents/ThinkBlueData/docker-classmap/app-backend/src/pages/coming-soon.vue'),
    /* no children */
  },
  _definePage_default_3
  ),
  _mergeRouteRecord(
  {
    path: '/login',
    name: 'login',
    component: () => import('/Users/hanwin/Documents/ThinkBlueData/docker-classmap/app-backend/src/pages/login.vue'),
    /* no children */
  },
  _definePage_default_4
  ),
  _mergeRouteRecord(
  {
    path: '/pending-task',
    name: 'pending-task',
    component: () => import('/Users/hanwin/Documents/ThinkBlueData/docker-classmap/app-backend/src/pages/pending-task.vue'),
    /* no children */
  },
  _definePage_default_5
  ),
  _mergeRouteRecord(
  {
    path: '/register',
    name: 'register',
    component: () => import('/Users/hanwin/Documents/ThinkBlueData/docker-classmap/app-backend/src/pages/register.vue'),
    /* no children */
  },
  _definePage_default_6
  ),
  _mergeRouteRecord(
  {
    path: '/schools',
    name: 'schools',
    component: () => import('/Users/hanwin/Documents/ThinkBlueData/docker-classmap/app-backend/src/pages/schools.vue'),
    /* no children */
  },
  _definePage_default_7
  ),
  _mergeRouteRecord(
  {
    path: '/schools/:id',
    name: 'schools-id',
    component: () => import('/Users/hanwin/Documents/ThinkBlueData/docker-classmap/app-backend/src/pages/schools.[id].vue'),
    /* no children */
  },
  _definePage_default_8
  ),
  _mergeRouteRecord(
  {
    path: '/schools/:id/daily-logs/:id2?',
    name: 'schools-id-daily-logs-id2?',
    component: () => import('/Users/hanwin/Documents/ThinkBlueData/docker-classmap/app-backend/src/pages/schools.[id].daily-logs.[[id2]].vue'),
    /* no children */
  },
  _definePage_default_9
  ),
  _mergeRouteRecord(
  {
    path: '/schools/:id/gallery',
    name: 'schools-id-gallery',
    component: () => import('/Users/hanwin/Documents/ThinkBlueData/docker-classmap/app-backend/src/pages/schools.[id].gallery.vue'),
    /* no children */
  },
  _definePage_default_10
  ),
  _mergeRouteRecord(
  {
    path: '/schools/:id/infrastructure',
    name: 'schools-id-infrastructure',
    component: () => import('/Users/hanwin/Documents/ThinkBlueData/docker-classmap/app-backend/src/pages/schools.[id].infrastructure.vue'),
    /* no children */
  },
  _definePage_default_11
  ),
  _mergeRouteRecord(
  {
    path: '/schools/:id/reports/:id2?',
    name: 'schools-id-reports-id2?',
    component: () => import('/Users/hanwin/Documents/ThinkBlueData/docker-classmap/app-backend/src/pages/schools.[id].reports.[[id2]].vue'),
    /* no children */
  },
  _definePage_default_12
  ),
  _mergeRouteRecord(
  {
    path: '/schools/:id/student',
    name: 'schools-id-student',
    component: () => import('/Users/hanwin/Documents/ThinkBlueData/docker-classmap/app-backend/src/pages/schools.[id].student.vue'),
    /* no children */
  },
  _definePage_default_13
  ),
  _mergeRouteRecord(
  {
    path: '/schools/:id/teachers/:id2?',
    name: 'schools-id-teachers-id2?',
    component: () => import('/Users/hanwin/Documents/ThinkBlueData/docker-classmap/app-backend/src/pages/schools.[id].teachers.[[id2]].vue'),
    /* no children */
  },
  _definePage_default_14
  )
]
