import {getData} from '@/utils/drupal'
import {useDrupalApi} from './useDrupalApi'

export const LOGIN_STATUS_PATH = '/user/login_status?_format=json'

export async function useLoginStatus(): Promise<Ref<boolean | null>> {
  const {data: response} = await useDrupalApi<boolean>(LOGIN_STATUS_PATH)
  return response
}

export function useLoginStatus2() {
  return useDrupalApi<boolean>(LOGIN_STATUS_PATH, {
    afterFetch(ctx) {
      ctx.data = ctx.data === 1;
      return ctx;
    }
  });
}
