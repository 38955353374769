<script setup lang="ts">
definePage({
  meta: {
    public: true,
    layout: 'blank',
  },
})
</script>

<template>
  <div class="misc-wrapper">
    <div>
      <!-- 👉 Title and subtitle -->
      <div class="text-center mb-4">
        <h2 class="text-h2 font-weight-medium mb-1">
          We are launching soon 🚀
        </h2>
        <p class="mb-6">
          Our website is opening soon. Please register to get notified when it's ready!
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@use "@core/scss/template/pages/misc.scss";

.misc-email-input {
  margin-inline: auto;
  max-inline-size: 21.875rem;
  min-inline-size: 12.5rem;
}
</style>
