<script setup lang="ts">
import _ from 'lodash'
import { useI18n } from 'vue-i18n'
import {API_BASE_URL} from '@/env'

const router = useRouter()
const route = useRoute()
const { t, locale } = useI18n();

const { data: currentUser } = useCurrentUser2();
const params = route.params as Record<string, string>;
const schoolUuid = ref<string>(params.id ? params.id : '');

const schoolStudentPath = ref(getSchoolStudentsPath(schoolUuid.value))
const schoolSystemsPath = ref(getTaxonomyPath('school-systems', locale.value));
const classTimePath = ref(getTaxonomyPath('class-times', locale.value));
const educationLevelsPath = ref(getTaxonomyPath('education-levels', locale.value));
const schoolStatusesPath = ref(getTaxonomyPath('school-statuses', locale.value));
const statesPath = ref(getTaxonomyPath('states', locale.value));
const districtsPath = ref(getTaxonomyPath('districts', locale.value));
const townshipsPath = ref(getTaxonomyPath('township', locale.value));

const { data: school } = useSchool(schoolUuid.value)
const { data: schoolStudents } = useSchoolStudents(schoolStudentPath);
const { data: teachers } = useTeachers(schoolUuid.value)
const { data: schoolSystems } = useSchoolSystems(schoolSystemsPath);
const { data: classTime } = useClassTime(classTimePath);
const { data: educationLevels } = useEducationLevels(educationLevelsPath);
const { data: schoolStatuses } = useSchoolStatus(schoolStatusesPath);
const { data: states } = useStates(statesPath);
const { data: districts } = useDistricts(districtsPath);
const { data: townships } = useTownships(townshipsPath);

const offeringEducation = ref([])
const nonOfferingEducations = ref([])
const isLoading = ref(false);

// Dialog
const isDialogVisible = ref(false)
const dialogReviewResult = ref(true)
const dialogReviewReason = ref('')

const actionItems = ref([
  { school_status: [7002], title: 'Change School Information', value: 'change_school_information' },
  // { school_status: 7002, title: 'Change Offering Education Level', value: 'change_offering_education_level' },
  { school_status: [undefined,7001], title: 'Review School', value: 'review_school' },
]);

onMounted(async () => {
  isLoading.value = true;
})

watch(
  () => [
    currentUser.value,
    school.value,
    schoolStudents.value,
    teachers.value,
    classTime.value,
    educationLevels.value,
    schoolStatuses.value,
    schoolSystems.value,
    states.value,
    districts.value,
    townships.value
  ],
  ([
    currentUser,
    school,
    schoolStudents,
    teachers,
    classTime,
    educationLevels,
    schoolStatuses,
    schoolSystems,
    states,
    districts,
    townships
  ]) => {
    if (
      currentUser &&
      school &&
      schoolStudents &&
      teachers &&
      classTime &&
      educationLevels &&
      schoolStatuses &&
      schoolSystems &&
      states &&
      districts &&
      townships
    ) {
      console.log('==== school detail page data ready =====')

      const bool = verifyTeacherSchoolPermission(currentUser, school, router);
      if (!bool) {
        router.push({ name: 'schools' })
      } else {
        isLoading.value = false;
        formatData();
      }
    }
  }
);

function formatData() {
  const foundTownShip = _.find(townships.value, (ts) => {
    return ts.uuid === school.value.field_township;
  });
  const foundState = _.find(states.value, (ts) => {
    return ts.uuid === school.value.field_state;
  });
  const foundDistrict = _.find(districts.value, (ts) => {
    return ts.uuid === school.value.field_district;
  });
  const foundStatus = _.find(schoolStatuses.value, (ts) => {
    return ts.uuid === school.value.field_school_status;
  });
  const foundSchoolSystem = _.find(schoolSystems.value, (ts) => {
    return ts.uuid === school.value.field_school_system;
  });

  school.value.township = foundTownShip ? foundTownShip : undefined;
  school.value.state = foundState ? foundState : undefined;
  school.value.district = foundDistrict ? foundDistrict : undefined;
  school.value.schoolSystem = foundSchoolSystem ? foundSchoolSystem : undefined;
  school.value.schoolStatus = foundStatus ? foundStatus : {id: undefined}

  // console.log('school.value', school.value);

  const offer = [];
  const nonOffer = [];
  _.each(educationLevels.value, (ed) => {
    const found = _.find(schoolStudents.value, (ss) => {
      return ss.field_student_education_level == ed.id;
    });
    let name = ed.name;
    if (found) {
      if (ed.id === 10007) {
        name+= ` (${found.field_specify})`;
      }
      offer.push(name);
    } else {
      nonOffer.push(name);
    }
  });
  offeringEducation.value = offer;
  nonOfferingEducations.value = nonOffer;
}

function selectAction(actionItem) {
  if (actionItem.value === 'review_school') {
    isDialogVisible.value = true
  } else if (actionItem.value === 'change_school_information') {
    const URL = `${API_BASE_URL}/node/${school.value.id}/edit`
    window.open(URL, '_blank');
  }
}

async function submitReview() {
  isDialogVisible.value = false
  const statusFindId = dialogReviewResult.value === true ? 7002 : 7001;
  const foundValidStatus = _.find(schoolStatuses.value, (d) => {
                            return d.id === statusFindId
                          });
  const foundPrinciple = _.find(teachers.value, (t)=> {
    return t.field_is_principal === true;
  });

  isLoading.value = true;
  // Update School
  const schoolUpdateObject = {
    field_review_result: dialogReviewReason.value
  };
  const relationData = {
    field_school_status: {
      data: [{type: 'taxonomy_term--school_status', id: foundValidStatus.uuid}]
    }
  }
  // Update Principle
  const updateUserObject = {
    uuid: foundPrinciple ? foundPrinciple.user_uuid : undefined,
    status: dialogReviewResult.value === true ? "1" : "0"
  };

  const updateTeacherObject = {
    field_is_approved: true
  };

  try {
    if (foundPrinciple) {
      await updateById('node', 'school', schoolUuid.value, schoolUpdateObject, relationData);
      await updateById('node', 'teacher', foundPrinciple.uuid, updateTeacherObject);
      await callCustomAPI('update-user-status', updateUserObject);
      console.log('School Update Finish !');
    } else {
      console.log('Cannot fined principle !');
    }
    
    location.reload();
  } catch (error: unknown) {
    console.log('onSubmitReview(): error', error)
    isLoading.value = false;
  }
}

definePage({
  meta: {
    breadcrumb: 'school_id',
  },
})
</script>

<template>
  <div>
    <SchoolDetailNavigation :school="school" />
  </div>
  <VCard>
    <VLayout>
      <VMain class="school-detail-page-container">
        <div class="pa-0 ma-0 w-100 h-100">
          <div v-if="isLoading" style="height: 600px;">
            <LoadingSpinner />
          </div>
          <VRow class='ma-0' v-if="!isLoading && school">
            <VCol cols="4" class="pt-2">
              <div>
                <h2>Genral Information</h2>
              </div>

              <VCard class="ma-5">
                <div class="information-box">
                  <div class="sub-title-text">School System</div>
                  <div class="">{{school.schoolSystem ? school.schoolSystem.name : ''}}</div>
                </div>
              </VCard>

              <VCard class="ma-5">
                <div class="information-box">
                  <h3>Time Stamp</h3>
                  <div class="sub-title-text">Registered on</div>
                  <div class="">{{school.created ? getFigmaDate(school.created) : ''}}</div>
                  <div class="sub-title-text mt-2">Recently updated on</div>
                  <div class="">{{school.changed ? getFigmaDate(school.changed) : ''}}</div>
                </div>
              </VCard>

            </VCol>
            <VCol cols="8" class="pt-2">

              <div class="ma-5 mt-0 action-button-container">
                <v-btn
                  color="primary">
                  Action
                  <v-menu activator="parent">
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in actionItems"
                        :class="school
                        && (item.school_status.indexOf(school.schoolStatus.id) == -1) ? 'hidden' : ''"
                        :key="index"
                        :value="index">
                        <v-list-item-title
                          @click="selectAction(item)">
                          {{ item.title }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-btn>
              </div>

              <VCard
                v-if="!school.schoolStatus || (school.schoolStatus.id === 7001)"
                class="ma-5">
                <div class="information-box">
                  <h3>Registration Note</h3>
                  <div class="sub-title-text">Note to UNESCO</div>
                  <div class="">{{school.field_memo}}</div>
                </div>
              </VCard>

              <VCard class="ma-5">
                <div class="information-box">
                  <h3>School Name</h3>
                  <VRow class='ma-0 mb-2'>
                    <VCol cols="6" class="pa-0">
                      <div class="sub-title-text">Full Name (English)</div>
                      <div class="">{{school.field_name}}</div>
                    </VCol>
                    <VCol cols="6" class="pa-0">
                      <div class="sub-title-text">Abbreviation (English)</div>
                      <div class="">{{school.field_abbreviation}}</div>
                    </VCol>
                  </VRow>

                  <VRow class='ma-0'>
                    <VCol cols="6" class="pa-0">
                      <div class="sub-title-text">Full Name (Myanma)</div>
                      <div class="">{{school.field_name_my}}</div>
                    </VCol>
                    <VCol cols="6" class="pa-0">
                      <div class="sub-title-text">Abbreviation (Myanma)</div>
                      <div class="">{{school.field_abbreviation_my}}</div>
                    </VCol>
                  </VRow>
                </div>
              </VCard>

              <VCard class="ma-5">
                <div class="information-box">
                  <h3>School Location</h3>
                  <VRow class='ma-0 mb-2'>
                    <VCol cols="4" class="pa-0">
                      <div class="sub-title-text">State</div>
                      <div class="">{{school.state ? school.state.name : ''}}</div>
                    </VCol>
                    <VCol cols="4" class="pa-0">
                      <div class="sub-title-text">District</div>
                      <div class="">{{school.district ? school.district.field_name : ''}}</div>
                    </VCol>
                    <VCol cols="4" class="pa-0">
                      <div class="sub-title-text">Postcode</div>
                      <div class="">{{school.field_postcode}}</div>
                    </VCol>
                  </VRow>
                </div>
              </VCard>

              <VCard class="ma-5">
                <div class="information-box">
                  <h3>School Contact</h3>
                  <div class="sub-title-text">School Email</div>
                  <div class="">{{school.field_email}}</div>
                </div>
              </VCard>

              <VCard class="ma-5">
                <div class="information-box">
                  <h3>Education Level</h3>
                  <div class="sub-title-text">Offering Education Level</div>
                  <div v-for="item in offeringEducation">
                    <span style="padding-left: 5px;padding-right: 5px;">•</span>{{item}}
                  </div>
                  <div class="sub-title-text mt-2">Non-offering Education Level</div>
                  <div v-for="item in nonOfferingEducations">
                    <span style="padding-left: 5px;padding-right: 5px;">•</span>{{item}}
                  </div>
                </div>
              </VCard>
            </VCol>
          </VRow>

          <VDialog
              v-model="isDialogVisible"
              width="auto">
              <v-card
                width="500"
                title="Review School">
                <div>
                  <div class="review-school-container information-box">
                    <VRow class='ma-0 mb-2'>
                      <VCol cols="12" class="pa-0 mb-3">
                        <div class="sub-title-text">Full Name (English)</div>
                        <div class="">{{school.field_name}}</div>
                      </VCol>
                      <VCol cols="12" class="pa-0 mb-3">
                        <div class="sub-title-text">State</div>
                        <div class="">{{school.state ? school.state.name : ''}}</div>
                      </VCol>
                      <VCol cols="12" class="pa-0 mb-3">
                        <div class="sub-title-text">Reviewed Result</div>
                        <v-radio-group v-model="dialogReviewResult">
                          <v-radio label="Approve" :value="true"></v-radio>
                          <v-radio label="Reject" :value="false"></v-radio>
                        </v-radio-group>
                      </VCol>
                      <VCol cols="12" class="pa-0 mb-3">
                        <div class="sub-title-text">Reviewed Reason</div>
                        <v-textarea
                          v-model="dialogReviewReason"
                          clearable
                          label=""
                          variant="outlined"></v-textarea>
                      </VCol>
                    </VRow>  
                  </div>

                </div>
                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    text="Discard"
                    variant="plain"
                    @click="isDialogVisible = false"
                  ></v-btn>

                  <v-btn
                    color="primary"
                    text="Submit"
                    variant="tonal"
                    @click="submitReview();"
                  ></v-btn>
                </v-card-actions>
              </v-card>
          </VDialog>
        </div>
      </VMain>
    </VLayout>
  </VCard>
</template>

<style lang="scss">
  .school-detail-page-container {
  }
  .review-school-container {
    max-height: 80vh;
    overflow: auto;
  }
</style>
