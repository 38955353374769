<script setup lang="ts">
import _ from 'lodash'
import {API_BASE_URL} from '@/env'
import csvDownload from 'json-to-csv-export'
import { useI18n } from 'vue-i18n'

const router = useRouter()
const route = useRoute()
const { t, locale } = useI18n();

const { data: currentUser } = useCurrentUser2();
const params = route.params as Record<string, string>;
const schoolUuid = ref<string>(params.id ? params.id : '');

const schoolStudentPath = ref(getSchoolStudentsPath(schoolUuid.value))
const gendersPath = ref(getTaxonomyPath('genders', locale.value));
const educationLevelsPath = ref(getTaxonomyPath('education-levels', locale.value));
const qualificationsPath = ref(getTaxonomyPath('qualifications', locale.value));
const schoolStatusesPath = ref(getTaxonomyPath('school-statuses', locale.value));

const { data: school } = useSchool(schoolUuid.value)
const { data: genders } = useGenders(gendersPath)
const { data: schoolStudents } = useSchoolStudents(schoolStudentPath);
const { data: educationLevels } = useEducationLevels(educationLevelsPath);
const { data: schoolStatuses } = useSchoolStatus(schoolStatusesPath)
const { data: dailyLogs } = useDailyLogs(schoolUuid.value);
const dailyLogStudents = ref(undefined);
const revisionObj = ref(undefined);

const activeTeachers = ref(undefined)
const isLoading = ref(false);

// Page Data
const searchText = ref('')
const studentList = ref([])
const selectedStudent = ref(undefined)
const studentObj = ref(undefined)
const studentCount = ref(0)

const tableTotalGenderData = ref({
  rows: [],
  columns: [
    { name: 'Education Level', field_name: 'name', type: 'text' },
    { name: 'Male', field_name: 'male',type: 'text'},
    { name: 'Female', field_name: 'female',type: 'text'},
    { name: 'Male (%)', field_name: 'male_percentage',type: 'text'},
    { name: 'Female (%)', field_name: 'female_percentage',type: 'text'},
  ]
})

const tableEducationGenderData = ref({
  rows: [],
  columns: [
    { name: 'Gender', field_name: 'name', type: 'text' },
    { name: 'This Education Level', field_name: 'count',type: 'text'},
    { name: 'All Education Levels', field_name: 'count_all',type: 'text'},
    { name: 'This Education Level (%)', field_name: 'count_percentage',type: 'text'},
    { name: 'All Education Levels (%)', field_name: 'count_all_percentage',type: 'text'},
  ]
})

const topRowStyles = {
  background: '#EBF4FA',
  fontWeight: 'bold'
}

const listActionItems = ref([
  { title: 'Download Student List', value: 'download_student' },
  { title: 'Manage Student Count', value: 'manage_student_count' },
]);

onMounted(async () => {
  isLoading.value = true; 
})

watch(locale, () => {
  isLoading.value = true;

  genders.value = undefined;
  qualifications.value = undefined;
  schoolStatuses.value = undefined;

  gendersPath.value = getTaxonomyPath('genders', locale.value);
  qualificationsPath.value = getTaxonomyPath('qualifications', locale.value);
  schoolStatusesPath.value = getTaxonomyPath('school-statuses', locale.value);
});

watch(
  () => [
    currentUser.value,
    school.value,
    schoolStudents.value,
    genders.value,
    educationLevels.value,
    schoolStatuses.value,
    dailyLogs.value
  ],
  ([
    currentUser,
    school,
    schoolStudents,
    genders,
    educationLevels,
    schoolStatuses,
    dailyLogs
  ]) => {
    if (currentUser
      && school
      && schoolStudents
      && genders
      && educationLevels
      && schoolStatuses
      && dailyLogs) {
      console.log('==== student page data ready =====')

      const bool = verifyTeacherSchoolPermission(currentUser, school, router);
      if (!bool) {
        router.push({ name: 'schools' })
      } else {
        isLoading.value = false;
        if (school) {
          formatSchoolData();
          formatListData();
        }
      }
    }
  }
);

function formatSchoolData() {
  const foundStatus = _.find(schoolStatuses.value, (ts) => {
    return ts.uuid === school.value.field_school_status;
  });
  school.value.schoolStatus = foundStatus ? foundStatus : undefined

  const tempRevision = {
    date: '',
    user: ''
  };
  if (schoolStudents.value.length) {
    tempRevision.date = schoolStudents.value[0].revision_timestamp;
    tempRevision.user = schoolStudents.value[0].revision_user;
  }
  revisionObj.value = tempRevision;
}

async function formatListData() {
  if (!searchText.value) {
    searchText.value = '';
  }

  // Map daily log data
  const dailyLogIds = _.map(dailyLogs.value, 'uuid').join(',');
  const {data: tempLoad } = await useDailyLogStudents(dailyLogIds);
  dailyLogStudents.value = tempLoad.value;
  const formattedDailyLogs = dailyLogs.value.map((d)=> {
    d.dailyLogStudents = dailyLogStudents.value.filter((ds)=> { return ds.field_daily_log === d.id; });
    d.educations = _.uniq(_.map(d.dailyLogStudents, 'field_education_level'));
    return d;
  });

  const textSearch = searchText.value.toLowerCase();
  let tempList = [];

  const sumMale = _.sumBy(schoolStudents.value, 'field_male_count');
  const sumFemale = _.sumBy(schoolStudents.value, 'field_female_count');
  const allTotal = sumMale + sumFemale;

  const genderColumnMapping = [
    { name: 'All', value: 'all', sum: allTotal},
    { name: 'Male', value: 'field_male_count', sum: sumMale},
    { name: 'Female', value: 'field_female_count', sum: sumFemale},
  ]

  _.each(educationLevels.value, (ed) => {
    const found = _.find(schoolStudents.value, (ss) => {
      return ss.field_student_education_level == ed.id;
    });
    let name = ed.name;
    if (found) {
      if (ed.id === 10007 && found.field_specify) {
        name+= ` (${found.field_specify})`;
      }
      ed.subtitle = 'Offering';
      ed.clickable = true;

      // Format table
      const tempTable = _.cloneDeep(tableEducationGenderData.value);
      const tempRows = [];
      const innerSum = found.field_male_count + found.field_female_count;
      _.each(genderColumnMapping, (g)=> {
        const count = g.value === 'all' ? found.field_male_count + found.field_female_count : found[g.value];
        let itemObj = {
          name: g.name,
          count: count.toString(),
          count_all: g.sum.toString(),
          count_percentage: getPercentage(count, allTotal) + '%',
          count_all_percentage: getPercentage(g.sum, allTotal) + '%'
        };

        if (g.value === 'all') { itemObj.styles = topRowStyles; }
        tempRows.push(itemObj);
      });
      tempTable.rows = tempRows;
      ed.sum = innerSum;
      ed.male = found.field_male_count;
      ed.female = found.field_female_count;
      ed.table = tempTable;

      // images
      const filteredDailyLogs = formattedDailyLogs.filter((dl)=> {
        return dl.educations.indexOf(ed.uuid) > -1;
      });

      ed.images = _.flatten(_.map(filteredDailyLogs, 'field_photo_gallery'))
    }
    ed.field_name = name;
    tempList.push(ed);
  });

  tempList = _.orderBy(tempList, 'subtitle');

  const tempAllObj = {
    name: 'Student',
    field_name: 'All Students',
    id: 'all',
    clickable: true,
    sum: allTotal
  };
  // Get Table
  const tempTotalTable = _.cloneDeep(tableTotalGenderData.value);
  const tempTotalRows = []

  tempTotalRows.push({
    name: 'All',
    male: sumMale.toString(),
    female: sumFemale.toString(),
    male_percentage: getPercentage(sumMale, allTotal) + '%',
    female_percentage: getPercentage(sumFemale, allTotal) + '%',
    styles: topRowStyles
  })

  _.each(tempList, (list)=> {
    if (list.clickable) {
      const itemObj = {
        name: list.field_name,
        male: list.male.toString(),
        female: list.female.toString(),
        male_percentage: getPercentage(list.male, allTotal) + '%',
        female_percentage: getPercentage(list.female, allTotal) + '%',
      }
      tempTotalRows.push(itemObj);
    }
  })

  tempTotalTable.rows = tempTotalRows;
  tempAllObj.table = tempTotalTable;


  tempList.unshift(tempAllObj);
  studentList.value = tempList;

  if (!selectedStudent.value) {
    selectedStudent.value = studentList.value[0];
  }
}

async function selectStudent(obj) {
  if (!obj.clickable) return;
  selectedStudent.value = _.cloneDeep(obj);
}

async function selectAction(actionItem) {
  if (actionItem.value === 'download_student') {
    const filename = `${school.value.field_name}_student_data`;
    const summaryRow = studentList.value[0];
    csvDownload({
      data: summaryRow.table.rows,
      filename: filename,
      delimiter: ','
    });
  } else if (actionItem.value === 'manage_student_count') {
    const URL = `${API_BASE_URL}/student-list?field_school_id=${school.value.id}`;
    window.open(URL, '_blank');
  }
}

definePage({
  meta: {
    breadcrumb: 'Student',
  },
})
</script>

<template>
  <div>
    <SchoolDetailNavigation :school="school" />
  </div>
  <VCard height="100%" width="100%">
    <VLayout>
      <VMain class="student-page-container">
        <div class="pa-0 ma-0 w-100 h-100">
          <div v-if="isLoading" style="height: 600px;">
            <LoadingSpinner />
          </div>
          <VRow class='ma-0' v-if="!isLoading && school">
            <VCol cols="4" class="pt-2">
              <div>
                <h2>Student</h2>
              </div>

              <div
                v-if="(school.schoolStatus && school.schoolStatus.id === 7002)"
                class="ma-5 mt-0 action-button-container">
                <v-btn
                  color="primary">
                  Action
                  <v-menu activator="parent">
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in listActionItems"
                        :key="index"
                        :value="index">
                        <v-list-item-title
                          @click="selectAction(item)">
                          <span v-if="item.value">{{ item.title }}</span>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-btn>
              </div>

              <VCard class="ma-5">
                <VList lines="two" class="student-list pa-0">
                  <VListItem
                      @click="selectStudent(listItem)"
                      :class="selectedStudent && selectedStudent.id === listItem.id ? 'selected' : ''"
                      class="student-item"
                      v-for="listItem in studentList"
                      :key="listItem.id"
                      :subtitle="listItem.subtitle"
                      :title="listItem.field_name">
                      <div
                        v-if="listItem.clickable"
                        class="item-icon-end">
                        <VIcon
                          size="20"
                          icon="tabler-arrow-right"
                        />
                      </div>
                    </VListItem>
                  </VList>
              </VCard>


            </VCol>
            <VCol cols="8" class="pt-2">

              <div v-if="selectedStudent && selectedStudent.id == 'all'">
                <VCard class="ma-5">
                  <div class="information-box pb-3">
                    <h3>{{selectedStudent.name}}</h3>
                    <div class="mb-2">
                      total {{selectedStudent.sum}} students
                    </div>
                    <div class="information-box pa-0">
                      <CustomTable :data="selectedStudent.table"/>
                    </div>
                  </div>
                </VCard>

                <VCard class="ma-5">
                  <div class="information-box pb-3">
                      <h3>Disability</h3>
                      <div>
                        {{school.field_disability_note}}
                      </div>
                  </div>
                </VCard>

                <VCard class="ma-5">
                  <div class="information-box pb-3">
                      <h3>Time Stamp</h3>
                      <VRow class='ma-0'>
                        <VCol cols="6" class="pa-0">
                          <div class="sub-title-text">Recently Updated on</div>
                          <div class="">{{revisionObj.date ? getFigmaDate(revisionObj.date) : ''}}</div>
                        </VCol>
                        <VCol cols="6" class="pa-0">
                          <div class="sub-title-text">Recently Updated by</div>
                          <div class="">{{revisionObj.user ? revisionObj.user : ''}}</div>
                        </VCol>
                      </VRow>
                  </div>
                </VCard>
              </div>

              <div v-if="selectedStudent && selectedStudent.id !== 'all'">
                <VCard class="ma-5">
                  <div class="information-box pb-3">
                    <h3>{{selectedStudent.field_name}}</h3>
                    <div class="mb-2">
                      total {{selectedStudent.sum}} students
                    </div>
                    <div class="information-box pa-0">
                      <CustomTable :data="selectedStudent.table"/>
                    </div>
                  </div>
                </VCard>

                <VCard class="ma-5">
                  <div class="information-box pb-3">
                    <h3>Class Photo of This Education Level</h3>
                    <div class="mb-2">
                      total {{selectedStudent.images.length}} photos
                    </div>
                    <div class="student-photo-box">
                      <div
                        v-for="(item, index) in selectedStudent.images"
                        class="image-item">
                        <a :href="item" target="_blank">
                          <img class="image-view" :src="item">
                        </a>
                      </div>
                    </div>
                  </div>
                </VCard>
              </div>
            </VCol>
          </VRow>
        </div>
      </VMain>
    </VLayout>
  </VCard>
</template>

<style lang="scss">
  .student-page-container {
  }
  .student-list {
    .student-item {
      border-bottom: 1px solid lightgrey;
      cursor: pointer;
      &.selected {
        background: #F1F4F6;
      }
    }
    .item-icon-end {
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
  .student-photo-box {
    .image-item {
      display: inline-block;
      margin: 5px;
    }
    .image-view {
      width: 100px;
      height: 100px;
    }
  }
</style>
