import type {RouteRecordRaw} from 'vue-router/auto'

import {useLoginStatus} from '@/composables/useLoginStatus'

export const redirects: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'redirect-index',
    redirect: () => {
      return {name: 'schools'}
    },
  }
]
