<script setup lang="ts">
import _ from 'lodash'
import csvDownload from 'json-to-csv-export'
import { useI18n } from 'vue-i18n'

import { API_BASE_URL } from '@/env';
import { insertNotification } from '@/utils/notification';

const router = useRouter()
const route = useRoute()
const { t, locale } = useI18n();

const params = route.params as Record<string, string>;
const schoolUuid = params.id;
const teacherUuid = params.id2;

const { data: currentUser } = useCurrentUser2();

const gendersPath = ref(getTaxonomyPath('genders', locale.value));
const qualificationsPath = ref(getTaxonomyPath('qualifications', locale.value));
const schoolStatusesPath = ref(getTaxonomyPath('school-statuses', locale.value));

const { data: school } = useSchool(schoolUuid);
const { data: teachers } = useTeachers(schoolUuid);
const { data: genders } = useGenders(gendersPath)
const { data: qualifications } = useQualifications(qualificationsPath)
const { data: schoolStatuses } = useSchoolStatus(schoolStatusesPath)

const activeTeachers = ref(undefined)
const isLoading = ref(false);

// Dialog
const isDialogVisible = ref(false)
const dialogReviewResult = ref(true)
const dialogReviewReason = ref('')

// Page Data
const searchText = ref('')
const teacherList = ref([])
const selectedTeacher = ref(undefined)
const teacherObj = ref(undefined)
const teacherCount = ref(0)
const principalCount = ref(0)
const activeTeacherCount = ref(0)
const selectedSort = ref(undefined);
const sortOptions = ref([
  { value: 'account_type', title: 'Account Type'},
  { value: 'field_name', title: 'Name'}
]);

const tableStatusData = ref({
  rows: [],
  columns: [
    { name: 'Teacher', field_name: 'name', type: 'text' },
    { name: 'Count', field_name: 'count',type: 'text'},
    { name: 'Percentage', field_name: 'percentage',type: 'text'}
  ]
})

const tableAgeData = ref({
  rows: [],
  columns: [
    { name: 'Age', field_name: 'name', type: 'text' },
    { name: 'Male', field_name: 'male',type: 'text'},
    { name: 'Female', field_name: 'female',type: 'text'},
    { name: 'Male (%)', field_name: 'male_percentage',type: 'text'},
    { name: 'Female (%)', field_name: 'female_percentage',type: 'text'}
  ]
})

const tableQualificationData = ref({
  rows: [],
  columns: [
    { name: 'Qualification', field_name: 'name', type: 'text' },
    { name: 'Male', field_name: 'male',type: 'text'},
    { name: 'Female', field_name: 'female',type: 'text'},
    { name: 'Male (%)', field_name: 'male_percentage',type: 'text'},
    { name: 'Female (%)', field_name: 'female_percentage',type: 'text'}
  ]
})

const topRowStyles = {
  background: '#EBF4FA',
  fontWeight: 'bold'
}

const actionItems = ref([
  { title: 'Change Information', value: 'change_information' },
  { field_is_approved: false, title: 'Review Teacher', value: 'review_teacher' },
  { field_is_approved: true, title: 'Apply Principal Tag', value: 'apply_principal' },
  { field_is_approved: true, title: 'Deactivate Account', value: 'deactivate_account' },
]);

const listActionItems = ref([
  { title: 'Download Teacher List', value: 'download_teacher' },
]);

onMounted(async () => {
  selectedSort.value = sortOptions.value[0].value;
  isLoading.value = true; 
})

watch(locale, () => {
  isLoading.value = true;

  genders.value = undefined;
  qualifications.value = undefined;
  schoolStatuses.value = undefined;

  gendersPath.value = getTaxonomyPath('genders', locale.value);
  qualificationsPath.value = getTaxonomyPath('qualifications', locale.value);
  schoolStatusesPath.value = getTaxonomyPath('school-statuses', locale.value);
});

watch(
  () => [
    currentUser.value,
    school.value,
    teachers.value,
    genders.value,
    qualifications.value,
    schoolStatuses.value
  ],
  ([
    currentUser,
    school,
    teachers,
    genders,
    qualifications,
    schoolStatuses
  ]) => {
    if (currentUser
      && school
      && teachers
      && genders
      && qualifications
      && schoolStatuses) {
      console.log('==== teacher page data ready =====')

      const bool = verifyTeacherSchoolPermission(currentUser, school, router);
      if (!bool) {
        router.push({ name: 'schools' });
        return;
      }
      if (!school) {
        console.log('school not found');
        alert(t('common.error'));
        isLoading.value = false;
        return;
      }
      formatSchoolData();
      formatListData();
      formatTableData();
      if (teacherUuid) {
        const selectedTeacher = teacherList.value.find(e => e.uuid === teacherUuid);
        if (selectedTeacher === undefined) {
          console.log('selectedTeacher not found');
          alert(t('common.error'));
          return;
        }
        selectTeacher(selectedTeacher);
      }
      isLoading.value = false;
    }
  }
);

function formatSchoolData() {
  const foundStatus = _.find(schoolStatuses.value, (ts) => {
    return ts.uuid === school.value.field_school_status;
  });
  school.value.schoolStatus = foundStatus ? foundStatus : undefined
}

function formatListData() {
  if (!searchText.value) {
    searchText.value = '';
  }
  const textSearch = searchText.value.toLowerCase();

  const mainList = _.cloneDeep(teachers.value);
  let filteredTeacher = _.filter(mainList, (t) => {
    let textBool = true;
    if (textSearch) {
      textBool = t.field_name.toLowerCase().indexOf(textSearch) > -1;
    }
    return textBool;
  });

  // Sort by field
  let sortedData = []
  if (selectedSort.value === 'account_type') {
    sortedData = _.orderBy(filteredTeacher, ['field_is_principal', 'field_is_approved','field_name'], ['desc', 'desc', 'asc'])
  } else {
    sortedData = _.sortBy(filteredTeacher, selectedSort.value);
  }

  const tempAllObj = {
    field_name: 'All Teachers',
    uuid: 'all'
  };
  sortedData.unshift(tempAllObj);
  teacherList.value = sortedData;
}

function formatTableData() {
  console.log('teacher', teachers.value);
  if (!selectedTeacher.value) {
    selectedTeacher.value = teacherList.value[0];
  }

  teacherCount.value = teachers.value ? teachers.value.length : 0;
  principalCount.value = teachers.value ? teachers.value.filter((d)=> {return d.field_is_principal}).length : 0;
  // Get Status Table
  const tempResult = [
    { name: 'All', count: teacherCount.value.toString(), percentage: '100%', styles: topRowStyles}
  ];
  const teacherStatusList = ['Active', 'Unreviewed', 'Deactivated']
  const statusTableData = _.each(teacherStatusList, (status) => {
    const tObject = {
      name: status,
      count: 0,
      percentage: 0
    };
    const filterStatus = status === 'Active' ? true : false;
    const filteredTeachers = _.filter(teachers.value, (t) => {
      return t.status_text === status
    });
    if (status === 'Active') {
      activeTeacherCount.value = filteredTeachers.length;
      activeTeachers.value = filteredTeachers;
    }
    tObject.count = filteredTeachers.length.toString(),
    tObject.percentage = getPercentage(filteredTeachers.length, teacherCount.value) + '%';
    tempResult.push(tObject);
  });

  // Age Table
  const maleObj = genders.value.find(g => g.name === 'Male');
  const femaleObj = genders.value.find(g => g.name === 'Female');
  const activeMale = maleObj ? activeTeachers.value.filter(act => act.field_gender === maleObj.id) : [];
  const activeFemale = femaleObj ? activeTeachers.value.filter(act => act.field_gender === femaleObj.id) : []; 

  const tempResultAge = [
    {
      name: 'Age and Qualification',
      male: activeMale.length.toString(),
      female: activeFemale.length.toString(),
      male_percentage:  getPercentage(activeMale.length, activeTeacherCount.value) + '%',
      female_percentage: getPercentage(activeFemale.length, activeTeacherCount.value) + '%',
      styles: topRowStyles
    }
  ];

  const ageTableMapping = [
    { name: '18 to 34 years old', min:18, max: 34},
    { name: '35 to 49 years old', min:35, max: 49},
    { name: '50 to 65 years old', min:50, max: 65},
    { name: '66 years and older', min:66, max: 9999}
  ]

  _.each(ageTableMapping, (at) => {
    const filteredMale = activeMale.filter(am => am.age >= at.min && am.age <= at.max);
    const filteredFemale = activeFemale.filter(am => am.age >= at.min && am.age <= at.max);
    const maleCount = filteredMale.length ? filteredMale.length : 0;
    const femaleCount = filteredFemale.length ? filteredFemale.length : 0;
    const tObject = {
      name: at.name,
      male: maleCount.toString(),
      female: femaleCount.toString(),
      male_percentage:  getPercentage(maleCount, activeTeacherCount.value) + '%',
      female_percentage: getPercentage(femaleCount, activeTeacherCount.value) + '%'
    };
    tempResultAge.push(tObject);
  })

  // Qualification Table
  const tempResultQualification = [
    {
      name: 'Age and Qualification',
      male: activeMale.length.toString(),
      female: activeFemale.length.toString(),
      male_percentage:  getPercentage(activeMale.length, activeTeacherCount.value) + '%',
      female_percentage: getPercentage(activeFemale.length, activeTeacherCount.value) + '%',
      styles: topRowStyles
    }
  ];
  _.each(qualifications.value, (qf) => {
    const filteredMale = activeMale.filter(am => am.field_qualification === qf.id);
    const filteredFemale = activeFemale.filter(am => am.field_qualification === qf.id);
    const maleCount = filteredMale.length ? filteredMale.length : 0;
    const femaleCount = filteredFemale.length ? filteredFemale.length : 0;
    const tObject = {
      name: qf.name,
      male: maleCount.toString(),
      female: femaleCount.toString(),
      male_percentage:  getPercentage(maleCount, activeTeacherCount.value) + '%',
      female_percentage: getPercentage(femaleCount, activeTeacherCount.value) + '%'
    };
    tempResultQualification.push(tObject);
  });

  tableStatusData.value.rows = tempResult;
  tableAgeData.value.rows = tempResultAge;
  tableQualificationData.value.rows = tempResultQualification;
}

async function selectTeacher(obj) {
  const routeLocation = router.resolve({ name: 'schools-id-teachers-id2?', params: {
    id: schoolUuid, id2: obj.uuid } });
  router.push(routeLocation);
  selectedTeacher.value = _.cloneDeep(obj);
  if (obj.uuid !== 'all') {
    // Get user information
    const tempFormatObj = selectedTeacher.value;
    const genderObj = genders.value.find(g => g.id === tempFormatObj.field_gender);
    const qualificationObj = qualifications.value.find(q => q.id === tempFormatObj.field_qualification);
    tempFormatObj.gender_text = genderObj ? genderObj.name : '-';
    tempFormatObj.qualification_text = qualificationObj ? qualificationObj.name : '-';
    tempFormatObj.age_text = tempFormatObj.age > 0 ? `${tempFormatObj.field_birth_year} (${tempFormatObj.age} years old)` : '-';
    teacherObj.value = tempFormatObj;
  }
}

async function selectAction(actionItem) {
  const schoolUuid = school.value?.uuid;
  const groupUuid = school.value?.group_uuid;
  if (currentUser.value === null || schoolUuid === undefined ||
    groupUuid === undefined || teacherObj.value === undefined
  ) {
    console.log('submitReview(): Master data not found');
    alert(t('common.error'));
    return;
  }

  if (actionItem.value === 'review_teacher') {
    isDialogVisible.value = true
  } else if (actionItem.value === 'apply_principal') {
    isLoading.value = true;
    const updateObject = {
      uuid: teacherObj.value.uuid,
      field_is_principal: !teacherObj.value.field_is_principal,
    };

    try {
      await updateById('node', 'teacher', teacherObj.value.uuid, updateObject);
      if (updateObject.field_is_principal) {
        await insertNotification(groupUuid, schoolUuid, 'became_principal',
          teacherObj.value.uuid);
      }
      location.reload();
    } catch (error: unknown) {
      console.log('onSubmit(): error', error)
      isLoading.value = false;
    }
  } else if (actionItem.value === 'deactivate_account') {
    isLoading.value = true;
    const updateObject = {
      uuid: teacherObj.value.uuid,
      field_is_approved: false,
      field_deactivated_on: getJsonApiDate(new Date())
    };

    const relationData: any = {
      field_deactivated_by: {
        data: [{
          type: 'user--user',
          id: currentUser.value.uuid
        }]
      }
    }

    try {
      await updateById('node', 'teacher', teacherObj.value.uuid, updateObject, relationData);
    } catch (error: unknown) {
      console.log('onSubmit(): error', error)
      isLoading.value = false;
    }

    const updateUserObject = {
      uuid: teacherObj.value.user_uuid,
      status: "0"
    };

    try {
      await callCustomAPI('update-user-status', updateUserObject);
      location.reload();
    } catch (error: unknown) {
      console.log('callCustomAPI(): error', error)
      isLoading.value = false;
    }
  } else if (actionItem.value === 'change_information') {
    const URL = `${API_BASE_URL}/node/${teacherObj.value.id}/edit`
    window.open(URL, '_blank');
  } else if (actionItem.value === 'download_teacher') {
    const filename = `${school.value.field_name}_teacher_data`;
    csvDownload({
      data: teachers.value,
      filename: filename,
      delimiter: ','
    });
  }
}

async function submitReview() {
  const schoolUuid = school.value?.uuid;
  const groupUuid = school.value?.group_uuid;
  if (currentUser.value === null || schoolUuid === undefined ||
    groupUuid === undefined || teacherObj.value === undefined
  ) {
    console.log('submitReview(): Master data not found');
    alert(t('common.error'));
    return;
  }

  isDialogVisible.value = false;
  if (dialogReviewResult.value) {
    isLoading.value = true;
    const updateObject = {
      uuid: teacherObj.value.uuid,
      field_is_approved: true,
    };

    const relationData: any = {
      field_approved_by: {
        data: [{
          type: 'user--user',
          id: currentUser.value.uuid,
        }],
      },
    };

    try {
      await updateById('node', 'teacher', teacherObj.value.uuid, updateObject, relationData);
      if (groupUuid) {
        await addNodeToGroup(groupUuid, teacherObj.value.uuid)
      }
      const updateUserObject = {
        uuid: teacherObj.value.user_uuid, status: '1',
      };
      await callCustomAPI('update-user-status', updateUserObject);
      if (groupUuid) {
        await addUserToGroup(groupUuid, teacherObj.value.user_uuid)
      }
      await insertNotification(groupUuid, schoolUuid, 'joined_school', teacherObj.value.uuid);
      location.reload();
    } catch (error: unknown) {
      console.log('onSubmit(): error', error)
      isLoading.value = false;
    }
  } else {
    isLoading.value = true;

    const updateObject = {
      uuid: teacherObj.value.uuid,
    };

    const relationData: any = {
      field_school: {
        data: {}
      }
    }

    try {
      await updateById('node', 'teacher', teacherObj.value.uuid, updateObject, relationData);
    } catch (error: unknown) {
      console.log('onSubmit(): error', error)
      isLoading.value = false;
    }

    const updateUserObject = {
      uuid: teacherObj.value.user_uuid,
      status: "0"
    };

    try {
      await callCustomAPI('update-user-status', updateUserObject);
      location.reload();
    } catch (error: unknown) {
      console.log('callCustomAPI(): error', error)
      isLoading.value = false;
    }
  }
}

definePage({
  meta: {
    breadcrumb: 'Teacher',
  },
})
</script>

<template>
  <div>
    <SchoolDetailNavigation :school="school" />
  </div>
  <VCard height="100%" width="100%">
    <VLayout>
      <VMain class="teacher-page-container">
        <div class="pa-0 ma-0 w-100 h-100">
          <div v-if="isLoading" style="height: 600px;">
            <LoadingSpinner />
          </div>
          <VRow class='ma-0' v-if="!isLoading && school">
            <VCol cols="4" class="pt-2">
              <div>
                <h2>Teacher</h2>
              </div>

              <div class="ma-5">
                <div class="mt-3 mb-0 d-flex align-start">
                  <VTextField
                    v-model="searchText"
                    clearable
                    label="Search"
                    prepend-inner-icon="tabler-search"
                    variant="outlined"
                    flat
                    hide-details
                    @update:modelValue="formatListData()"
                  />
                </div>
                <div class="mt-3 d-flex align-start">
                  <div>
                    <VSelect
                      v-model="selectedSort"
                      :items="sortOptions"
                      @update:model-value="formatListData()"
                    />
                  </div>
                  <div class="ms-2 mt-2">Total {{teacherCount}} teachers</div>
                </div>
              </div>

              <div
                v-if="(school.schoolStatus && school.schoolStatus.id === 7002)"
                class="ma-5 mt-0 action-button-container">
                <v-btn
                  color="primary">
                  Action
                  <v-menu activator="parent">
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in listActionItems"
                        :key="index"
                        :value="index">
                        <v-list-item-title
                          @click="selectAction(item)">
                          <span v-if="item.value">{{ item.title }}</span>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-btn>
              </div>

              <VCard class="ma-5">
                <VList lines="two" class="teacher-list pa-0">
                  <VListItem
                      @click="selectTeacher(listItem)"
                      :class="selectedTeacher && selectedTeacher.uuid === listItem.uuid ? 'selected' : ''"
                      class="teacher-item"
                      v-for="listItem in teacherList"
                      :key="listItem.uuid"
                      :subtitle="listItem.subtitle"
                      :title="listItem.field_name">
                      <div class="item-icon-end">
                        <VIcon
                          size="20"
                          icon="tabler-arrow-right"
                        />
                      </div>
                    </VListItem>
                  </VList>
              </VCard>

            </VCol>
            <VCol cols="8" class="pt-2">
              <div v-if="selectedTeacher.uuid === 'all'">
                <VCard class="ma-5">
                  <div class="information-box pb-3">
                    <h3>Teacher</h3>
                    <div>
                      Total {{activeTeacherCount}} active teachers
                    </div>
                  </div>
                  <div class="information-box pt-0 pb-3">
                    <CustomTable :data="tableStatusData"/>
                  </div>
                </VCard>

                <VCard class="ma-5">
                  <div class="information-box pb-3">
                    <h3>Active Teacher Count</h3>
                  </div>
                  <div class="information-box pt-0 pb-3">
                    <CustomTable :data="tableAgeData"/>
                  </div>
                  <div class="information-box pt-0 pb-3">
                    <CustomTable :data="tableQualificationData"/>
                  </div>
                </VCard>
              </div>

              <div v-if="selectedTeacher.uuid !== 'all'">
                <div class="ma-5 mt-0 action-button-container">
                  <v-btn
                    color="primary">
                    Action
                    <v-menu activator="parent">
                      <v-list>
                        <v-list-item
                          v-for="(item, index) in actionItems"
                          :class="(teacherObj
                            && item.field_is_approved !== undefined
                            && (teacherObj.field_is_approved !== item.field_is_approved))
                          || (item.value === 'apply_principal'
                            && teacherObj
                            && principalCount >= 2
                            && !teacherObj.field_is_principal) ? 'hidden' : ''"
                          :key="index"
                          :value="index">
                          <v-list-item-title
                            @click="selectAction(item)">
                            <span v-if="item.value!== 'apply_principal'">{{ item.title }}</span>
                            <span v-if="item.value== 'apply_principal'">
                              {{ teacherObj.field_is_principal ? 'Cancel Principal' : 'Apply Principal' }}
                            </span>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-btn>
                </div>

                <VCard class="ma-5">
                  <div class="information-box pb-3">
                    <h3>Teacher Information</h3>
                    <div class="mb-2">
                      <v-avatar :image="teacherObj.field_avatar_url" size="80"></v-avatar>
                    </div>

                    <VRow class='ma-0 mb-2'>
                      <VCol cols="6" class="pa-0">
                        <div class="sub-title-text">Display Name</div>
                        <div class="">{{teacherObj.field_name}}</div>
                      </VCol>
                      <VCol cols="6" class="pa-0">
                        <div class="sub-title-text">Phone Number</div>
                        <div class="">{{getFigmaPhoneNumber(teacherObj.field_phone_number)}}</div>
                      </VCol>
                    </VRow>

                    <VRow class='ma-0 mb-2'>
                      <VCol cols="6" class="pa-0">
                        <div class="sub-title-text">Account Type</div>
                        <div class="">Teacher</div>
                      </VCol>
                      <VCol cols="6" class="pa-0">
                        <div class="sub-title-text">Principal Tag</div>
                        <div class="">{{teacherObj.field_is_principal ? 'Principal' : 'Normal teacher'}}</div>
                      </VCol>
                    </VRow>
                  </div>
                </VCard>

                <VCard class="ma-5">
                  <div class="information-box pb-3">
                    <h3>Personal Information</h3>
                    <VRow class='ma-0 mb-2'>
                      <VCol cols="4" class="pa-0">
                        <div class="sub-title-text">Gender</div>
                        <div class="">{{teacherObj.gender_text}}</div>
                      </VCol>
                      <VCol cols="4" class="pa-0">
                        <div class="sub-title-text">Birth Year</div>
                        <div class="">{{teacherObj.age_text}}</div>
                      </VCol>
                      <VCol cols="4" class="pa-0">
                        <div class="sub-title-text">Qualification</div>
                        <div class="">{{teacherObj.qualification_text}}</div>
                      </VCol>
                    </VRow>
                  </div>
                </VCard>

                <VCard class="ma-5">
                  <div class="information-box pb-3">
                    <h3>Time Stamp</h3>

                    <VRow class='ma-0 mb-2'>
                      <VCol cols="6" class="pa-0">
                        <div class="sub-title-text">Last Log In On</div>
                        <div class="">{{teacherObj.user_login ? getFigmaDate(teacherObj.user_login) : '-'}}</div>
                      </VCol>
                    </VRow>

                    <VRow class='ma-0 mb-2'>
                      <VCol cols="6" class="pa-0">
                        <div class="sub-title-text">Registered On</div>
                        <div class="">{{teacherObj.user_created ? getFigmaDate(teacherObj.user_created) : '-'}}</div>
                      </VCol>
                      <VCol cols="6" class="pa-0">
                        <div class="sub-title-text">Verified By</div>
                        <div class="">{{teacherObj.field_approved_by_name ? teacherObj.field_approved_by_name : '-'}}</div>
                      </VCol>
                    </VRow>

                    <VRow class='ma-0 mb-2'>
                      <VCol cols="6" class="pa-0">
                        <div class="sub-title-text">Deactivated On</div>
                        <div class="">{{teacherObj.field_is_approved || (!teacherObj.field_is_approved && !teacherObj.field_deactivated_on) ? '-' : getFigmaDate(teacherObj.field_deactivated_on)}}</div>
                      </VCol>
                      <VCol cols="6" class="pa-0">
                        <div class="sub-title-text">Deactivated By</div>
                        <div class="">{{teacherObj.field_is_approved || (!teacherObj.field_is_approved && !teacherObj.field_deactivated_by_name) ? '-' : teacherObj.field_deactivated_by_name}}</div>
                      </VCol>
                    </VRow>
                  </div>
                </VCard>
              </div>
            </VCol>
          </VRow>

          <VDialog
            v-model="isDialogVisible"
            width="auto">
            <v-card
              width="500"
              title="Review Requesting Teacher">
              <div>
                <div class="review-school-container information-box">
                  <VRow class='ma-0 mb-2'>
                    <VCol cols="12" class="pa-0 mb-3">
                      <div class="sub-title-text">Display Name</div>
                      <div class="">{{teacherObj.field_name}}</div>
                    </VCol>
                    <VCol cols="12" class="pa-0 mb-3">
                      <div class="sub-title-text">Account Type</div>
                      <div class="">Teacher</div>
                    </VCol>
                    <VCol cols="12" class="pa-0 mb-3">
                      <div class="sub-title-text">Phone Number</div>
                      <div class="">{{getFigmaPhoneNumber(teacherObj.field_phone_number)}}</div>
                    </VCol>
                    <VCol cols="12" class="pa-0 mb-3">
                      <div class="sub-title-text">
                        Reviewed Result
                        <span class="req-indicator"> *</span>
                      </div>
                      <v-radio-group
                        v-model="dialogReviewResult">
                        <v-radio label="Approve" :value="true"></v-radio>
                        <v-radio label="Reject" :value="false"></v-radio>
                      </v-radio-group>
                    </VCol>
                    <VCol cols="12" class="pa-0 mb-3">
                      <div class="sub-title-text">
                        Review Reason
                        <span class="req-indicator"> *</span>
                      </div>
                      <v-textarea
                        v-model="dialogReviewReason"
                        clearable
                        label=""
                        variant="outlined"></v-textarea>
                    </VCol>
                  </VRow>  
                </div>

              </div>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  text="Discard"
                  variant="plain"
                  @click="isDialogVisible = false"
                ></v-btn>

                <v-btn
                  :disabled="!dialogReviewReason.length"
                  color="primary"
                  text="Submit"
                  variant="tonal"
                  @click="submitReview();"
                ></v-btn>
              </v-card-actions>
            </v-card>
          </VDialog>
        </div>
      </VMain>
    </VLayout>
  </VCard>
</template>

<style lang="scss">
  .teacher-page-container {
  }
  .teacher-list {
    .teacher-item {
      border-bottom: 1px solid lightgrey;
      cursor: pointer;
      &.selected {
        background: #F1F4F6;
      }
    }
    .item-icon-end {
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
</style>
