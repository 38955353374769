<script setup lang="ts">
import _ from 'lodash'
import { useI18n } from 'vue-i18n'
import {API_BASE_URL} from '@/env'
import { ref } from 'vue'
import { useField, useForm } from 'vee-validate'
import type { FetchOptions } from '@/utils/fetch'
import VOtpInput from "vue3-otp-input";

const router = useRouter()
const route = useRoute()
const { t, locale } = useI18n();

const logoUrl = `${API_BASE_URL}/themes/custom/classmap_theme/public/classmap-icon-3.png`
const backgroundUrl = `${API_BASE_URL}/themes/custom/classmap_theme/public/backend/register_bg.jpeg`

const { handleSubmit, handleReset } = useForm({
  validationSchema: {
    name (value) {
      if (value?.length >= 2) return true
      return 'Display Name needs to be at least 2 characters.'
    },
    email (value) {
      const re = /\S+@\S+\.\S+/;
      if (re.test(value)) return true
      return 'Must be a valid e-mail.'
    },
    password (value) {
      if (value?.length >= 8) return true
      return 'Password needs to be at least 8 characters.'
    },
    repassword (value) {
      if (repassword.value.value !== password.value.value) {
        return 'Password and confirm password must be same.'
      } else {
        return true;
      }
    },
  },
})

// General
const isLoading = ref(false);
const formStep = ref(1);
const returnErrorMessege = ref('');

// Form 1
const name = useField('name')
const email = useField('email')
const password = useField('password')
const repassword = useField('repassword')
const showPass = ref(false);
const showPass2 = ref(false);

// Form 2
const selectedAuthentype = ref('sms')
const phone = ref('')
const otpRef = ref('')

const submit = handleSubmit(values => {
  registerFormSubmit();
})

// Form 3 OTP
const otpInput = ref<InstanceType<typeof VOtpInput> | null>(null);
const bindModal = ref("");
const isOtpFilled = ref(undefined);
const isOtpValid = ref(undefined);
const optInputValue = ref(undefined)

const handleOnComplete = (value: string) => {
  // console.log("OTP completed: ", value);
  // if otp valid
  isOtpFilled.value = true;
};
const handleOnChange = (value: string) => {
  // console.log("OTP changed: ", value);
  isOtpFilled.value = false;
};

// Function
async function registerFormSubmit() {
  console.log('registerFormSubmit', name.value.value);
  // returnErrorMessege.value = error.message;

  try {
    const emailValue = email.value.value;
    const result = await getData<any>('api/v1/email-validate?_format=json', {
      method: 'POST',
      body: JSON.stringify({email: emailValue}),
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    });

    const status = result.status;
    if (!status) {
      returnErrorMessege.value = result.value;
    } else {
      formStep.value = 2;
    }

  } catch (error: any) {
    console.log('Email validation error !!', error);
    returnErrorMessege.value = error.message;
  }
}

async function phoneFormSubmit(value) {
  console.log('phoneFormSubmit', value);
  phone.value = value;
  const emailValue = email.value.value;

  isLoading.value = true;
  // Do OTP request
  try {
    const result = await getData<any>('api/v1/otp-request?_format=json', {
      method: 'POST',
      body: JSON.stringify({phone_number: phone.value, email: emailValue}),
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    });

    isLoading.value = false;
    if (result.status === true) {
      // console.log('true',result);
      otpRef.value = result.ref;
      formStep.value = 3;
    } else {
      // console.log('false',result);
    }
  } catch (error: any) {
    // setLoginErrorMsg(error.msg);
    isLoading.value = false;
    console.log('doOTPRequest(): catch', error);
  }
}

async function verifyOTP() {
  const ref = otpRef.value;
  const inputOtp = optInputValue.value;
  isLoading.value = true;
  try {
    const result = await getData<any>('api/v1/otp-validate?_format=json', {
      method: 'POST',
      body: JSON.stringify({otp: inputOtp, ref_code: ref}),
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }),
    });
    isLoading.value = false;
    if (result.status === true) {
      // validate pass
      isOtpValid.value = true;
      // Call api for register here
      createAccount()
    } else {
      console.log('validate fail')
      isOtpValid.value = false;
    }
  } catch (error: unknown) {
  // alert(t('screens.otp.invalidPhoneNumber'));
    isLoading.value = false;
    console.log('validateOTP(): catch', error);
  }
}

async function createAccount() {
  const emailValue = email.value.value;
  const displayNameValue  = name.value.value;
  const phoneValue = phone.value;
  const passwordValue = password.value.value;

  // use email as useraccount for now

  const userAccount = {
    // name: [{value: phoneValue}],
    name: [{value: emailValue}],
    pass: [{value: passwordValue}],
    mail: [
      {
        value: emailValue
      },
    ],
    // roles: [{target_id: 'unesco_partner'}],
    field_displayed_name: [{value: displayNameValue}],
    preferred_langcode: [{value: locale.value}],
  };

  isLoading.value = true;
  try {
    const fetchOptions: FetchOptions = {};
    fetchOptions.body = JSON.stringify(userAccount);
    const _fetchOptions = { method: 'POST', headers: getApiHeader(), ...fetchOptions }
    // console.log('getApiHeader', getApiHeader());
    // console.log('_fetchOptions', _fetchOptions);

    // const registerResponse = await fetchDrupal('POST', '/user/register?_format=json', options);
    const registerResponse = await fetchDrupal('/web/user/register?_format=json', _fetchOptions);
    const registerResult = await registerResponse;

    // console.log('registerResult:', registerResult);
    // console.log(registerResult.uid[0].value);
    isLoading.value = false;
    const new_user_uuid = registerResult && registerResult.uuid ? registerResult.uuid[0].value : undefined;

    if (new_user_uuid) {
      // Update user
      await updateUserRole(new_user_uuid, 'teacher', 'unesco_partner');
      formStep.value = 4;
    } else {
      alert('Errors occurred registration. Please check the form and submit it again.');
    }

  } catch (error: unknown) {
    console.log('onSubmit(): error', error)
    returnErrorMessege.value = error.message;
    isLoading.value = false;
  }
}

function navigateToLoginPage(): void {
  router.push({ name: 'login' })
}

onMounted(async () => {
  // name.value.value = 'Phet Register'
  // email.value.value = 'phet@thinkbluedata.com'
  // password.value.value = '1234567890'
  // repassword.value.value = '1234567890'
})

definePage({
  meta: {
    breadcrumb: false,
    unauthenticatedOnly: true,
  }
})

</script>

<template>
  <VCard height="100%" width="100%">
    <VLayout class="h-100">
      <VMain class="register-page-container">
        <div class="pa-0 ma-0 w-100 h-100">
          <div v-if="isLoading" style="height: 600px;">
            <LoadingSpinner />
          </div>
          <VRow class='ma-0 h-100' v-if="!isLoading">
            <VCol cols="12" class="pt-2">
              <div class="mb-2 d-flex">
                <div class="pa-2">
                  <img style="width: 60px;" :src="logoUrl" />
                </div>
                <div>
                  <h1>Class Map</h1>
                  <div>An education management information system (EMIS) that is used to support schools and learning centre in Myanmar.</div>
                </div>
              </div>
              <div
                v-bind:style="{ 'background-image': 'url(' + backgroundUrl + ')' }"
                class="register-page-body h-100">
                <div
                  v-if="formStep === 1"
                  class="register-box first-form">
                  <div class="mb-3"><h1>Registration</h1></div>
                  <form class="mb-5" @submit.prevent="submit">
                      <v-text-field
                        class="mb-3"
                        v-model="email.value.value"
                        :error-messages="email.errorMessage.value"
                        label="E-mail"></v-text-field>

                      <v-text-field
                        class="mb-3"
                        v-model="name.value.value"
                        :error-messages="name.errorMessage.value"
                        label="Display Name"></v-text-field>

                      <v-text-field
                        class="mb-3"
                        :append-inner-icon="showPass ? 'tabler-eye' : 'tabler-eye-off'"
                        v-model="password.value.value"
                        :error-messages="password.errorMessage.value"
                        :type="showPass ? 'text' : 'password'"
                        @click:append-inner="showPass = !showPass"
                        label="Password"></v-text-field>

                      <v-text-field
                        class="mb-3"
                        :append-inner-icon="showPass2 ? 'tabler-eye' : 'tabler-eye-off'"
                        v-model="repassword.value.value"
                        :error-messages="repassword.errorMessage.value"
                        :type="showPass2 ? 'text' : 'password'"
                        @click:append-inner="showPass2 = !showPass2"
                        label="Confirm Password"></v-text-field>

                      <v-btn
                        class="me-4"
                        type="submit">
                        Continue
                      </v-btn>

                      <v-btn @click="handleReset">
                        Clear
                      </v-btn>

                      <div v-if="returnErrorMessege">
                        <span style="color: red;">{{returnErrorMessege}}</span>
                      </div>
                  </form>

                  <div class="link-text text-center" @click="navigateToLoginPage()">Back to log in</div>
                </div>

                <div
                  v-if="formStep === 2"
                  class="register-box second-form">
                  <div class="mb-3"><h1>Two-authentication</h1></div>
                  <div class="sub-title-text">Second Verification Method</div>
                  <div>
                    <v-radio-group v-model="selectedAuthentype">
<!--                       <v-radio label="Google Authenticator" value="google"></v-radio>
                      <v-radio label="Microsoft Authenticator" value="micorsoft"></v-radio> -->
                      <v-radio label="SMS" value="sms" ></v-radio>
                    </v-radio-group>
                  </div>
                  <div v-if="selectedAuthentype === 'sms'">
                    <div class="sub-title-text">
                     Phone Number
                      <span class="req-indicator"> *</span>
                    </div>
                    <PhoneFormInput @submit="phoneFormSubmit($event)" />
                  </div>
                </div>

                <div
                  v-if="formStep === 3"
                  class="register-box third-form">
                  <div class="mb-3"><h1>Two-authentication</h1></div>
                  <div class="sub-title-text">Enter the 6-digit number from the authenticator.</div>
                  <div><span>Ref : {{otpRef}}</span></div>
                  <div class="otp-input-box mb-3">
                    <div style="display: flex; flex-direction: row">
                      <v-otp-input
                        ref="otpInput"
                        input-classes="otp-input"
                        inputType="letter-numeric"
                        :num-inputs="6"
                        v-model:value="optInputValue"
                        :should-auto-focus="true"
                        :should-focus-order="true"
                        @on-change="handleOnChange"
                        @on-complete="handleOnComplete"
                        :placeholder="['*', '*', '*', '*', '*', '*']"
                      />
                    </div>
                  </div>
                  <div v-if="isOtpValid === false" class="mb-3">
                    <span style="color: red;">The number is not matched. Please try again.</span>
                  </div>
                  <div class="mb-3">
                    <v-btn
                      @click="verifyOTP()"
                      :disabled="!isOtpFilled"
                      class="me-4">
                      Continue
                    </v-btn>
                  </div>

                  <div v-if="returnErrorMessege">
                    <span style="color: red;">{{returnErrorMessege}}</span>
                  </div>
                </div>

                <div
                  v-if="formStep === 4"
                  class="register-box fourth-form">
                  <div class="mb-3"><h1>Registration Complete</h1></div>
                  <div class="mb-3">Your registration process is completed. You can log in after get approved by admin. Please contact the site administration if you face issue.</div>
                  <div class="link-text" @click="navigateToLoginPage()">Back to log in</div>
                </div>
              </div>
          	</VCol>
          </VRow>
        </div>
      </VMain>
    </VLayout>
  </VCard>
</template>

<style lang="scss">
  .register-page-body {
    background-size: cover;
    position: relative;
    min-height: 700px;
    .register-box {
      background: white;
      width: 450px;
      border: 1px solid lightgray;
      padding: 40px;
      position: absolute;
      top: 50px;
      right: 50px;
      .sub-title-text {
        color: #7F888F;
        font-size: 14px;
      }
      .req-indicator {
        color: red;
      }
    }
    .otp-input-box {
      .otp-input {
        width: 40px;
        height: 40px;
        padding: 5px;
        // margin: 0 10px;
        margin-right: 10px;
        font-size: 20px;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        text-align: center;
      }
      /* Background colour of an input field with value */
      .otp-input.is-complete {
        background-color: #e4e4e4;
      }
      .otp-input::-webkit-inner-spin-button,
      .otp-input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input::placeholder {
        font-size: 15px;
        text-align: center;
        font-weight: 600;
      }
    }
    .link-text {
      cursor: pointer;
      color: #0077D4;
      text-decoration: underline;
    }
  }
</style>