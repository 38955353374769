<script setup lang="ts">
import { ref } from 'vue'
import { useField, useForm } from 'vee-validate'

import {useDrupalApi} from '@/composables/useDrupalApi'
import {getData} from '@/utils/drupal'
import {API_BASE_URL} from '@/env'

type LoginResult = {
  current_user: {
      uid: number
      roles: string[]
      name: string
  },
  csrf_token: string
  logout_token: string
}

const router = useRouter()
const route = useRoute()
const { t, locale } = useI18n();

const logoUrl = `${API_BASE_URL}/themes/custom/classmap_theme/public/classmap_logo.png`
const backgroundUrl = `${API_BASE_URL}/themes/custom/classmap_theme/public/backend/login_bg.jpeg`

const { handleSubmit, handleReset } = useForm({
  validationSchema: {
    email (value) {
      // const re = /\S+@\S+\.\S+/;
      // if (re.test(value)) return true
      // return 'Must be a valid e-mail.
      return true;
    },
    password (value) {
      if (value?.length >= 8) return true
      return 'Password needs to be at least 8 characters.'
    },
  },
})

// General
const returnErrorMessege = ref('');

// Form 1
const email = useField('email')
const password = useField('password')
const showPass = ref(false);

const submit = handleSubmit(values => {
  console.log('Login !!')
  doLogin();
})

function navigateToPage (page: string) {
  router.push({name: page})
}

function navigateToDrupalPage (page: string) {
  let url = '';
  if (page === 'login') {
    url = API_BASE_URL + '/user/login?destination='  + window.location.origin + '/schools';
  } else if (page === 'forget-pass') {
    url = API_BASE_URL + '/user/password';
  }
  window.location.href = url;
}

async function doLogin(): Promise<void> {

  // const username = 'admin';
  // const pass = 'Wdwc4KrRxhVUj28CuJWkjmef';

  const username = email.value.value;
  const pass = password.value.value;

  try {
    const fetchOptions: FetchOptions = {};
    fetchOptions.body = JSON.stringify({ name: username, pass: pass });
    const _fetchOptions = { method: 'POST', headers: getApiHeader(), ...fetchOptions }
    const testResponse = await fetchDrupal('/web/user/login?_format=json', _fetchOptions);
    const testResult = await testResponse;

    // if (response.value === null) {
    //   // alert('Login failed')
    //   returnErrorMessege.value = 'Login failed!! Please try again.';
    //   console.log(response)
    //   return
    // }
    if (testResult.current_user) {
      router.push({name: 'schools'})
    }
  } catch (error: unknown) {
    console.log('onLogin(): error', error.message)
    if (error.message.includes('<!DOCTYPE'))
      returnErrorMessege.value = "The account don't have access in the web.";
    else
      returnErrorMessege.value = error.message;
  }
}

onMounted(async () => {
  const loginStatus = await useLoginStatus()
  const isLoggedIn = loginStatus.value
  if (isLoggedIn) {
    router.push({name: 'schools'})
  }
})

definePage({
  meta: {
    breadcrumb: false,
    unauthenticatedOnly: true,
  }
})
</script>

<template>
  <VCard height="100%" width="100%">
    <VLayout class="h-100">
      <VMain class="login-page-container">
        <div class="pa-0 ma-0 w-100 h-100">
          <div v-if="isLoading" style="height: 600px;">
            <LoadingSpinner />
          </div>
          <VRow class='ma-0 h-100' v-if="!isLoading">
            <VCol cols="12" class="pt-2">
              <div class="mb-2 d-flex">
                <div class="pa-2">
                  <img style="width: 60px;" :src="logoUrl" />
                </div>
                <div>
                  <h1>Class Map</h1>
                  <div>An education management information system (EMIS) that is used to support schools and learning centre in Myanmar.</div>
                </div>
              </div>
              <div
                v-bind:style="{ 'background-image': 'url(' + backgroundUrl + ')' }"
                class="login-page-body h-100">
                <div
                  class="login-box first-form">
                  <div class="mb-3"><h1>Login</h1></div>
                  <form class="mb-5" @submit.prevent="submit">
                      <v-text-field
                        class="mb-3"
                        v-model="email.value.value"
                        :error-messages="email.errorMessage.value"
                        label="E-mail"></v-text-field>

                      <v-text-field
                        class="mb-3"
                        :append-inner-icon="showPass ? 'tabler-eye' : 'tabler-eye-off'"
                        v-model="password.value.value"
                        :error-messages="password.errorMessage.value"
                        :type="showPass ? 'text' : 'password'"
                        @click:append-inner="showPass = !showPass"
                        label="Password"></v-text-field>

                      <v-btn
                        class="me-4"
                        type="submit">
                        Continue
                      </v-btn>

                      <v-btn @click="handleReset">
                        Clear
                      </v-btn>
                  </form>

                  <div class="mb-3" v-if="returnErrorMessege">
                    <span style="color: red;">{{returnErrorMessege}}</span>
                  </div>

                  <div class="d-flex">
                    <div class="link-text text-center mb-3 mr-auto" @click="navigateToDrupalPage('forget-pass')">Forgot password</div>

                    <div class="link-text text-center mb-3" @click="navigateToPage('register')">Register new account</div>
                  </div>

                  <div
                    style="width: fit-content;"
                    class="link-text text-center mb-3 mr-auto ms-auto"
                    @click="navigateToDrupalPage('login')">
                    Log in from drupal site
                  </div>
                </div>
              </div>
            </VCol>
          </VRow>
        </div>
      </VMain>
    </VLayout>
  </VCard>
</template>

<style lang="scss">
  .login-page-body {
    background-size: cover;
    position: relative;
    min-height: 700px;
    .login-box {
      background: white;
      width: 450px;
      border: 1px solid lightgray;
      padding: 40px;
      position: absolute;
      top: 50px;
      right: 50px;
      .sub-title-text {
        color: #7F888F;
        font-size: 14px;
      }
      .req-indicator {
        color: red;
      }
    }
    .link-text {
      cursor: pointer;
      color: #0077D4;
      text-decoration: underline;
    }
  }
</style>
