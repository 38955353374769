<script setup lang="ts">
import _ from 'lodash'
import { useI18n } from 'vue-i18n'

import { API_BASE_URL } from '@/env'
import type { DailyLogStudent } from '@/models/nodes/DailyLogStudent';
import type { Relationships } from '@/utils/drupal';
import { insertNotification } from '@/utils/notification';

type PresentStudent = {
  [x: number]: {
    female: number
    male: number
    max_female: number
    max_male: number
  }
}

const router = useRouter()
const route = useRoute()
const { t, locale } = useI18n();

const params = route.params as Record<string, string>;
const schoolUuid = params.id;
const dailyLogUuid = params.id2;
const isLoading = ref(false);

const { data: currentUser } = useCurrentUser2();

const schoolStudentPath = ref(getSchoolStudentsPath(schoolUuid))
const classTimePath = ref(getTaxonomyPath('class-times', locale.value));
const educationLevelsPath = ref(getTaxonomyPath('education-levels', locale.value));

const { data: school } = useSchool(schoolUuid)
const { data: schoolStudents } = useSchoolStudents(schoolStudentPath);
const { data: teachers } = useTeachers(schoolUuid)
const { data: classTime } = useClassTime(classTimePath);
const { data: educationLevels } = useEducationLevels(educationLevelsPath);

const selectedDate = ref<Date>(new Date())
const list = ref([])
const selectedAttendance = ref(undefined)
const attendanceData = ref(undefined)

const listActionItems = ref([
  { title: 'Change Class Information', value: 'change_class_information' },
  { title: 'Delete Class Attendance', value: 'delete_class_attendance' },
]);

// Dialog
const isDialogVisible = ref(false)

// Dialog Options
const dDate = ref<Date>(new Date())
const dClassTime = ref(undefined)
const dEducationLevels = ref<number[]>([])
const dTeachers = ref([])
const dStudentCount = ref(0)
const dPresentStudentObj = ref<PresentStudent>({})
const dClassNote = ref('')
const dPhotos = ref([])
const availableEducations = ref([])
const isStudentCountValid = ref(true)

const dStudentInputRules = {
  min(min: number, v: number) {
    return v >= min || `Student number must be at least ${min}`;
  },
  max(max: number, v: number) { 
    return v <= max || `Student number may not be greater than ${max}.`;
  }
}

const dPhotosRules =  {
  size(files) {
    return !files.some(file => file.size > 4_194_304) || 'Phoyo size should be less than 4 MB!'
  },
  count(files) {
    return files.length <= 12 || 'Maximum photos is 12'
  }
}

onMounted(async () => {
  isLoading.value = true
})

watch(
  () => [
    currentUser.value,
    school.value,
    schoolStudents.value,
    teachers.value,
    classTime.value,
    educationLevels.value,
  ],
  async ([
    currentUser,
    school,
    schoolStudents,
    teachers,
    classTime,
    educationLevels,
  ]) => {
    if (
      currentUser &&
      school &&
      schoolStudents &&
      teachers &&
      classTime &&
      educationLevels) {
      console.log('==== daily log page data ready =====')

      prepareListData();

      const bool = verifyTeacherSchoolPermission(currentUser, school, router);
      if (!bool) {
        router.push({ name: 'schools' });
        return;
      }
      if (!school) {
        console.log('school not found')
        alert(t('common.error'));
        isLoading.value = false;
        return;
      }
      if (dailyLogUuid) {
        const dailyLogData = await useDailyLog(dailyLogUuid);
        console.log('dailyLog', dailyLogData);
        selectedDate.value = new Date(dailyLogData.dailyLog.field_date)
      }
      await selectDate(selectedDate.value);
      if (dailyLogUuid) {
        const selectedItem = list.value.find(e => e.uuid === dailyLogUuid);
        if (selectedItem === undefined) {
          console.log('selectedItem not found');
          alert(t('common.error'));
          isLoading.value = false;
          return;
        }
        selectAttendance(selectedItem);
      }
    }
  }
);
watch(dEducationLevels, (dEducationLevels) => {
  const tmpPresentStudentObj: PresentStudent = JSON.parse(JSON.stringify(dPresentStudentObj.value))
  for (const key of Object.keys(tmpPresentStudentObj).map(e => Number(e))) {
    if (dEducationLevels.includes(key) === false) {
      // Clear deselected value
      tmpPresentStudentObj[key] = { ...tmpPresentStudentObj[key], female: 0, male: 0, }
    }
  }
  console.log(tmpPresentStudentObj)
  dPresentStudentObj.value = tmpPresentStudentObj
  updatePresentStudentNumber()
})

function prepareListData() {
  const mockPresentStudentObj: PresentStudent = {};
  _.each(educationLevels.value,(d)=> {
    const foundSchoolStudent = _.find(schoolStudents.value, (ed) => {
      return ed.field_student_education_level === d.id;
    });

    if (foundSchoolStudent) {
      availableEducations.value.push(d);
      mockPresentStudentObj[d.id] = {
        male: 0,
        female: 0,
        max_male: foundSchoolStudent.field_male_count,
        max_female: foundSchoolStudent.field_female_count
      };
    }
  })
  dPresentStudentObj.value = mockPresentStudentObj;
}

function selectAttendance(obj: any) {
  const routeLocation = router.resolve({ name: 'schools-id-daily-logs-id2?', params: {
    id: schoolUuid, id2: obj.uuid } });
  router.push(routeLocation);
  selectedAttendance.value = _.cloneDeep(obj);
}

async function selectDate(obj: any) {
  isLoading.value = true;
  // console.log('selectDate', obj);
  selectedDate.value = obj;
  const { data: dailyLogs } = await useDailyLogs(schoolUuid, selectedDate.value);

  let formattedList = [];
  for (const dailyLog of dailyLogs.value) {
    // console.log('dailyLog', dailyLog);
    const logObj = await useDailyLog(dailyLog.uuid);
    // console.log('logObj', logObj);
    const dailyLogObj = logObj.dailyLog;
    const dailyLogStudents = logObj.dailyLogStudents;

    const foundClassTime = _.find(classTime.value, (d) => {
      return d.uuid === dailyLogObj.field_class_time;
    });

    const foundLogStudents = _.map(dailyLogStudents, (dl) => {
      const foundEducation = _.find(educationLevels.value, (ed) => {
        return ed.uuid === dl.field_education_level;
      });

      dl.field_female_count = Number(dl.field_female_count);
      dl.field_male_count = Number(dl.field_male_count);
      dl.education_level = foundEducation ? foundEducation.name : '';
      return dl
    });

    const foundTeachers = _.map(dailyLogObj.field_teachers, (t) => {
      const foundTeacher = _.find(teachers.value, (ed) => {
        return ed.uuid === t
      });
      return foundTeacher;
    });

    const foundLogAbsentStudents = _.map(_.cloneDeep(foundLogStudents), (st) => {
      const foundEducation = _.find(educationLevels.value, (ed) => {
        return ed.uuid === st.field_education_level;
      });
      const foundSchoolStudent = _.find(schoolStudents.value, (sst) => {
        return sst.field_student_education_level === Number(foundEducation.id);
      });

      if (foundSchoolStudent) {
        st.field_female_count = foundSchoolStudent.field_female_count - st.field_female_count;
        st.field_male_count = foundSchoolStudent.field_male_count - st.field_male_count;
        st.field_female_count = st.field_female_count < 0 ? 0 : st.field_female_count;
        st.field_male_count = st.field_male_count < 0 ? 0 : st.field_male_count;
      }
      return st;
    });

    const educationSumObj = {
      education_level: 'All Levels',
      field_female_count: _.sumBy(foundLogStudents, 'field_female_count'),
      field_male_count: _.sumBy(foundLogStudents, 'field_male_count')
    };

    const educationAbsentSumObj = {
      education_level: 'All Levels',
      field_female_count: _.sumBy(foundLogAbsentStudents, 'field_female_count'),
      field_male_count: _.sumBy(foundLogAbsentStudents, 'field_male_count')
    };

    const foundPhotos = _.cloneDeep(dailyLogObj.field_photo_gallery);

    const formattedObj = {
      uuid: dailyLogObj.uuid,
      id: dailyLogObj.id,
      data: logObj,
      classTime: foundClassTime ? foundClassTime.name : 'No Class time Information',
      classTimeId: foundClassTime ? foundClassTime.id : '0',
      dateText: dailyLogObj.field_date,
      teachers: foundTeachers,
      educations: foundLogStudents,
      educationText: foundLogStudents.length ? _.map(foundLogStudents, 'education_level').join(', ') : '',
      educationAbsents: foundLogAbsentStudents,
      photos: foundPhotos
    }

    formattedObj.educations.unshift(educationSumObj);
    formattedObj.educationAbsents.unshift(educationAbsentSumObj);
    formattedList.push(formattedObj);
  }

  formattedList = _.orderBy(formattedList, 'classTimeId');
  list.value = formattedList;
  selectedAttendance.value = formattedList.length ? formattedList[0] : undefined;

  isLoading.value = false;
}

async function submitDialog() {
  const groupUuid = school.value?.group_uuid;
  // let groupUuid = currentUser.value?.group_uuids[0]
  // const isAdmin = currentUser.value?.roles[0].includes('administrato') || currentUser.value?.roles[0].includes('unesco');
  // if (isAdmin) {
  //   // Use group uuid from school data instead
  //   console.log('admin user detect !!');
  //   groupUuid = school.value?.group_uuid;
  // }

  if (groupUuid === undefined) {
    console.log('submitDialog(): school wasn\'t belong to any group');
    alert(t('common.error'));
    return
  }

  const schoolUuid = school.value?.uuid;
  const now = new Date().getTime()
  const dailyLogData = {
    title: `${schoolUuid}_${now}`,
    field_date: getYmdDate(dDate.value),
    field_note: dClassNote.value,
  };

  const relationData: Relationships = {
    field_school: {
      data: [{ type: 'node--school', id: schoolUuid as string, }]
    },
    field_teachers: { data: [] },
  }
  if (dClassTime.value) {
    const foundClassTime = _.find(classTime.value, (d) => {
      return d.id === dClassTime.value;
    });

    relationData.field_class_time = {
      data: [{ type: 'taxonomy_term--class_time', id: foundClassTime.uuid }]
    };
  }

  for (const teacher of dTeachers.value) {
    const foundTeacher = _.find(teachers.value, (ed) => {
      return ed.id === teacher
    });
    relationData.field_teachers.data.push({ type: 'node--teacher', id: foundTeacher.uuid, })
  }

  isLoading.value = true

  try {
    const dailyLogResult = await insert('node', 'daily_log', dailyLogData, relationData, groupUuid)
    if (dEducationLevels.value.length) {
      let index = 0;
      for (const studentEdu of dEducationLevels.value) {
        const foundEducation = _.find(educationLevels.value, (ed) => {
          return ed.id === studentEdu;
        });

        const dailyLogStudentData: Partial<DailyLogStudent> = {
          title: `${dailyLogResult.uuid}_${now}_${(index + 1)}`,
          field_female_count: dPresentStudentObj.value[foundEducation.id].female,
          field_male_count: dPresentStudentObj.value[foundEducation.id].male,
          field_school_female_count: dPresentStudentObj.value[foundEducation.id].max_female,
          field_school_male_count: dPresentStudentObj.value[foundEducation.id].max_male,
        }

        const dailyLogStudentRelationData = {
          field_daily_log: {
            data: [{ type: 'node--daily_log', id: dailyLogResult.uuid, }],
          },
          field_education_level: {
            data: [{ type: 'taxonomy_term--education_level',
              id: foundEducation.uuid, }],
          },
        }

        await insert('node', 'daily_log_student',
          dailyLogStudentData, dailyLogStudentRelationData, groupUuid)
        index++;
      }
    }

    if (dPhotos.value.length) {
      const photoRelationData = {data: []}

      for (const file of dPhotos.value) {
        const fileName = `${file.name}.jpg`
        const res = await uploadFile('node', 'daily_log', 'field_photo_gallery', fileName, file);

        photoRelationData.data.push({
          type: 'file--file',
          id: res.uuid,
          data: {meta: {alt: fileName}},
        });
      }
      await insertRelationsById('node', 'daily_log', dailyLogResult.uuid,
          'field_photo_gallery', photoRelationData);
    }

    await insertPendingTask(groupUuid, 'unreviewed_daily_log', dailyLogResult.uuid, locale.value)
    await insertNotification(groupUuid, schoolUuid, 'created_daily_log', dailyLogResult.uuid);
    isLoading.value = false
    location.reload();
    // isDialogVisible.value = false
  } catch (error: unknown) {
    console.log('handleOnSendMessage(): error', error);
    alert(t('common.error'));
    isDialogVisible.value = false
    isLoading.value = false
  }

}

async function updateReviewStatus() {
  const schoolUuid = school.value?.uuid;
  const groupUuid = school.value?.group_uuid;
  if (schoolUuid === undefined || groupUuid === undefined ||
    selectedAttendance.value === undefined
  ) {
    console.log('selectedAttendance not found');
    alert(t('common.error'));
    return;
  }
  isLoading.value = true;
  const bool = !selectedAttendance.value.data.dailyLog.field_is_reviewed;

  // Set update value to variable
  selectedAttendance.value.data.dailyLog.field_is_reviewed = bool;
  list.value = list.value.map((d)=> {
    if (d.id === selectedAttendance.value.id) {
      d.data.dailyLog.field_is_reviewed = bool;
    }
    return d;
  });

  // Update drupal data
  const updateObject = {
    uuid: selectedAttendance.value.uuid,
    field_is_reviewed: bool
  };
  try {
    await updateById('node', 'daily_log', selectedAttendance.value.uuid, updateObject);
    if (updateObject.field_is_reviewed) {
      await insertNotification(groupUuid, schoolUuid, 'reviewed_daily_log',
        selectedAttendance.value.uuid);
    }
    isLoading.value = false;
  } catch (error: unknown) {
    console.log('onSubmit(): error', error)
    isLoading.value = false;
  }
}

async function selectAction(actionItem) {
  if (actionItem.value === 'change_class_information') {
    console.log('selectedAttendance', selectedAttendance.value);
    const URL = `${API_BASE_URL}/node/${selectedAttendance.value.id}/edit`;
    window.open(URL, '_blank');
  } else if (actionItem.value === 'delete_class_attendance') {
    const URL = `${API_BASE_URL}/node/${selectedAttendance.value.id}/delete`;
    window.open(URL, '_blank');
  }
}

function updatePresentStudentNumber() {
  let sum = 0;
  let bool = true;
  const keys = Object.keys(dPresentStudentObj.value).map(e => Number(e));
  _.each(keys, (k) => {
    sum+= (Number(dPresentStudentObj.value[k].male) + Number(dPresentStudentObj.value[k].female));

    if (dPresentStudentObj.value[k].male < 0
      || dPresentStudentObj.value[k].male > dPresentStudentObj.value[k].max_male) {
      bool = false;
    }

    if (dPresentStudentObj.value[k].female < 0
      || dPresentStudentObj.value[k].female > dPresentStudentObj.value[k].max_female) {
      bool = false;
    }
  })

  isStudentCountValid.value = bool;
  dStudentCount.value = sum;
}

definePage({
  meta: {
    breadcrumb: 'Daily Log',
  },
})
</script>

<template>
  <div>
    <SchoolDetailNavigation :school="school" />
  </div>
  <VCard height="100%" width="100%">
    <VLayout>
      <VMain class="daily-log-page-container">
        <div class="pa-0 ma-0 w-100 h-100">
          <div v-if="isLoading" style="height: 600px;">
            <LoadingSpinner />
          </div>
          <VRow class='ma-0' v-if="!isLoading">
            <VCol cols="4" class="pt-2">
              <div>
                <h2>Daily Attendance</h2>
              </div>
              <div class="">
                <v-date-picker
                  position="relative"
                  class="daily-log-datepicker"
                  hide-header="true"
                  :model-value="selectedDate"
                  @update:model-value="selectDate"
                  show-current="true"></v-date-picker>
              </div>

              <div
                class="ma-5 ms-0 mt-0 action-button-container">
                <v-btn
                  @click="isDialogVisible = true"
                  color="primary">
                  Add Class Attendance
                </v-btn>
              </div>

              <div>
                <h4>Class Attendance of this day</h4>

                <div v-if="!isLoading && selectedAttendance == undefined">
                  <div class="warning-box d-flex">
                    <div><VIcon
                      size="24"
                      icon="tabler-alert-circle"/></div>
                    <div class="ms-1">No update for this day. Let’s add some information to keep track on your school.</div>
                  </div>
                </div>

                <div>
                  <VList lines="two" class="attendance-list">
                    <VListItem
                        @click="selectAttendance(listItem)"
                        :class="selectedAttendance.uuid === listItem.uuid ? 'selected' : ''"
                        class="attendance-item"
                        v-for="listItem in list"
                        :key="listItem.uuid"
                        :subtitle="listItem.educationText"
                        :title="listItem.classTime">
                        <div class="item-icon-end">
                          <VIcon
                            size="20"
                            icon="tabler-arrow-right"
                          />
                        </div>
                      </VListItem>
                    </VList>
                </div>
              </div>
            </VCol>
            <VCol cols="8" class="pt-2">
              <div class="w-100">
                  <div v-if="selectedAttendance">
                    <div class="ma-5 mt-0 action-button-container d-flex align-start">
                      <v-btn
                        color="primary">
                        Action
                        <v-menu activator="parent">
                          <v-list>
                            <v-list-item
                              v-for="(item, index) in listActionItems"
                              :key="index"
                              :value="index">
                              <v-list-item-title
                                @click="selectAction(item)">
                                <span>{{ item.title }}</span>
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-btn>

                      <div class="ms-2 pe-2" style="background: #EBF4FA;">
                        <VCheckboxBtn
                          :model-value="selectedAttendance.data.dailyLog.field_is_reviewed"
                          @update:modelValue="updateReviewStatus()"
                          label="Reviewed">
                        </VCheckboxBtn>
                      </div>
                    </div>

                    <VCard class="ma-5">
                      <div class="information-box pb-3">
                        <h3>Class Information</h3>
                      </div>
                      <div class="information-box pt-0 pb-3">
                        <VRow class='ma-0 mb-2'>
                          <VCol cols="6" class="pa-0">
                            <div class="text-box">
                              <div class="sub-title-text">Class Date</div>
                              <div class="text-detail">{{selectedAttendance.dateText}}</div>
                            </div>
                            <div class="text-box">
                              <div class="sub-title-text">Education Level</div>
                              <div
                                v-for="(item, index) in selectedAttendance.educations"
                                class="text-detail">
                                <div v-if="item.education_level !== 'All Levels'">
                                  <span style="padding-left: 5px;padding-right: 5px;">•</span>{{item.education_level}}
                                </div>
                              </div>
                            </div>
                          </VCol>

                          <VCol cols="6" class="pa-0">
                            <div class="text-box">
                              <div class="sub-title-text">Class Time</div>
                              <div class="text-detail">{{selectedAttendance.classTime ? selectedAttendance.classTime : '-'}}</div>
                            </div>
                            <div class="text-box">
                              <div class="sub-title-text">Teacher</div>
                              <div
                                v-for="(item, index) in selectedAttendance.teachers"
                                class="text-detail">
                                <span style="padding-left: 5px;padding-right: 5px;">•</span>{{item.field_name}}
                              </div>
                            </div>
                          </VCol>
                        </VRow>
                      </div>
                    </VCard>

                    <VCard class="ma-5">
                      <div class="information-box pb-3">
                        <h3>Present Student Count</h3>
                      </div>
                      <div class="information-box pt-0 pb-3">
                        <div class="student-table">
                           <div class="row-item row-header">
                             <div class="label-column">Education Level</div>
                             <div class="count-column">Male</div>
                             <div class="count-column">Female</div>
                           </div>
                            <div
                              v-for="(item, index) in selectedAttendance.educations"
                              class="row-item">
                               <div class="label-column">{{item.education_level}}</div>
                               <div class="count-column">{{item.field_male_count}}</div>
                               <div class="count-column">{{item.field_female_count}}</div>
                            </div>
                        </div>
                      </div>

                      <div class="information-box pb-3">
                        <h3>Absent Student Count</h3>
                      </div>
                      <div class="information-box pt-0 pb-3">
                        <div class="student-table">
                           <div class="row-item row-header">
                             <div class="label-column">Education Level</div>
                             <div class="count-column">Male</div>
                             <div class="count-column">Female</div>
                           </div>
                            <div
                              v-for="(item, index) in selectedAttendance.educationAbsents"
                              class="row-item">
                               <div class="label-column">{{item.education_level}}</div>
                               <div class="count-column">{{item.field_male_count}}</div>
                               <div class="count-column">{{item.field_female_count}}</div>
                            </div>
                        </div>
                      </div>
                    </VCard>

                    <VCard class="ma-5">
                      <div class="information-box pb-3">
                        <h3>Class Photo Gallery</h3>
                        <div>Total {{selectedAttendance.photos.length}} photos</div>
                      </div>
                      <div class="information-box pt-0 pb-3">
                        <div
                          v-for="(item, index) in selectedAttendance.photos"
                          class="image-item">
                            <a :href="item.uri" target="_blank">
                              <img class="image-view" :src="item.thumbnail_uri">
                            </a>
                        </div>
                      </div>
                    </VCard>
                  </div>
              </div>
            </VCol>
          </VRow>

          <VDialog
            v-model="isDialogVisible"
            width="auto">
            <v-card
              width="500"
              title="Class Attendace">
              <div>
                <div class="create-attendance-container information-box">
                  <div>
                    <h3>Class Time</h3>
                    <div class="sub-title-text">Class Date<span class="req-indicator"> *</span></div>
                    <div>
                      <v-date-input
                        v-model="dDate"
                        label="Date input"></v-date-input>
                    </div>

                    <div class="sub-title-text">Class Time</div>
                    <div>
                      <v-radio-group v-model="dClassTime">
                        <v-radio
                          v-for="cItem in classTime"
                          :key="cItem.uuid"
                          :label="cItem.name" :value="cItem.id" ></v-radio>
                      </v-radio-group>
                    </div>
                  </div>

                  <div>
                    <h3>Class Education</h3>
                    <div class="sub-title-text">Education Level<span class="req-indicator"> *</span></div>
                    <div>
                      <v-checkbox
                        v-for="cEdu in availableEducations"
                        :key="cEdu.uuid"
                        v-model="dEducationLevels"
                        :label="cEdu.name"
                        :value="cEdu.id"
                      ></v-checkbox>
                    </div>
                  </div>

                  <div>
                    <h3>Class Teacher</h3>
                    <div class="sub-title-text">Teacher<span class="req-indicator"> *</span></div>
                    <div>
                      <v-checkbox
                        v-for="cTeacher in teachers"
                        :key="cTeacher.uuid"
                        v-model="dTeachers"
                        :label="cTeacher.field_name"
                        :value="cTeacher.id"
                      ></v-checkbox>
                    </div>
                  </div>

                  <div>
                    <h3>Present Student</h3>
                    <div>Total {{dStudentCount}} students</div>

                    <div>
                      <div v-for="cEdu in educationLevels" :key="cEdu.uuid">
                        <div v-if="dEducationLevels.indexOf(cEdu.id) > -1">
                          <h4>{{cEdu.name}}</h4>
                           <VRow class='ma-0 mb-2'>
                              <VCol cols="6" class="pt-2">
                                <div class="sub-title-text">Male<span class="req-indicator"> *</span></div>
                                <v-text-field
                                  class=""
                                  type="number"
                                  :max="dPresentStudentObj[cEdu.id].max_male"
                                  min="0"
                                  :rules="[
                                    dStudentInputRules.min(0, dPresentStudentObj[cEdu.id].male),
                                    dStudentInputRules.max(
                                      dPresentStudentObj[cEdu.id].max_male,
                                      dPresentStudentObj[cEdu.id].male
                                    )
                                  ]"
                                  @input="updatePresentStudentNumber"
                                  v-model.number="dPresentStudentObj[cEdu.id].male"></v-text-field>
                              </VCol>
                              <VCol cols="6" class="pt-2">
                                <div class="sub-title-text">Female<span class="req-indicator"> *</span></div>
                                <v-text-field
                                  class=""
                                  type="number"
                                  :max="dPresentStudentObj[cEdu.id].max_female"
                                  min="0"
                                  :rules="[
                                    dStudentInputRules.min(0, dPresentStudentObj[cEdu.id].female),
                                    dStudentInputRules.max(
                                      dPresentStudentObj[cEdu.id].max_female,
                                      dPresentStudentObj[cEdu.id].female
                                    )
                                  ]"
                                  @input="updatePresentStudentNumber"
                                  v-model.number="dPresentStudentObj[cEdu.id].female"></v-text-field>
                              </VCol>
                           </VRow>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <h3>Class Information</h3>
                    <div class="sub-title-text">Class Note</div>
                    <div>
                      <v-textarea v-model="dClassNote"></v-textarea>
                    </div>

                    <div class="sub-title-text mt-2">Photo</div>
                    <div>Total {{dPhotos.length}} photos</div>
                    <div>
                      <v-file-input
                        accept="image/*"
                        v-model="dPhotos"
                        label="File input"
                        :rules="[
                          dPhotosRules.size(dPhotos),
                          dPhotosRules.count(dPhotos)
                        ]"
                        multiple
                      ></v-file-input>
                    </div>
                  </div>
                </div>
              </div>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  text="Discard"
                  variant="plain"
                  @click="isDialogVisible = false"></v-btn>
                <v-btn
                  :disabled="!dEducationLevels.length
                    || !dTeachers.length
                    || dStudentCount === 0
                    || dPhotosRules.size(dPhotos) !== true
                    || dPhotosRules.count(dPhotos) !== true
                    || !isStudentCountValid"
                  color="primary"
                  text="Submit"
                  variant="tonal"
                  @click="submitDialog();"></v-btn>
              </v-card-actions>
            </v-card>
          </VDialog>


        </div>
      </VMain>
    </VLayout>
  </VCard>
</template>

<style lang="scss">
  .daily-log-page-container {
    .daily-log-datepicker {
      .v-date-picker-month__day {
        height: 30px !important;
        width: 30px !important;
      }
    }
    .warning-box {
      background: #FFF3F0;
      color: #D8552C;
      border: 1px solid #D8552C;
      padding: 10px;
      margin-top: 20px;
    }
  }
  .attendance-list {
    .attendance-item {
      border-bottom: 1px solid lightgrey;
      cursor: pointer;
      &.selected {
        background: #F1F4F6;
      }
    }
    .item-icon-end {
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
  .attendance-detail-box {
    .text-box {
      margin-bottom: 10px;
    }
    .text-title {
      color: #7F888F
    }
    .text-detail {
      color: #212121
    }
  }
  .information-box  {
    .student-table {
      font-size: 12px;
      .row-item {
        height: 30px;
        padding: 8px;
      }
      .row-header {
        background: #F1F4F6;
        font-weight: bold;
      }
      .label-column {
        display: inline-table;
        width: 60%;
      }
      .count-column {
        display: inline-table;
        width: 20%;
      }
    }
  }
  .information-box {
    .image-item {
      display: inline-block;
      margin: 5px;
    }
    .image-view {
      width: 100px;
      height: 100px;
    }
  }
  .create-attendance-container {
    max-height: 600px;
    overflow: scroll;
  }
</style>
