<script setup lang="ts">
import { orderBy } from 'lodash';

import { useI18n } from 'vue-i18n';

import type { RouteLocation } from 'vue-router';

import { PENDING_TASK_REQ_SCHOOL_ID, PENDING_TASK_REV_APPR_TEACHER_ID, PENDING_TASK_REV_TEACHER_ID, PENDING_TASK_UNREV_DAILY_LOG_ID,
  PENDING_TASK_UNREV_REPORT_ID} from '@/constant'
import type { Data } from '@/components/CustomTable.vue';
import type { OptionItem } from '@/models/Util';
import { getFigmaDate } from '@/utils/general';
import { getPendingTaskListPath, usePendingTaskList } from '@/composables/usePendingTask';
import { RouteLocationResolvedTypedList } from 'unplugin-vue-router';

const SORT_OPTION_ITEMS: OptionItem[] = [
  { value: 'created', title: 'Recently Added' },
  { value: 'pending_task_type_name', title: 'Task Type' },
  { value: 'school_name', title: 'School Name' },
];

const TASK_TYPE_FILTER_ITEMS = ref([]);
const IS_COMPLETE_FILTER_ITEMS = [{name: 'Any', id: 'any'}, {name: 'Yes', id: '1'}, {name: 'No', id: '0'}]

const ITEMS_PER_PAGE = 10;

const { locale, t } = useI18n();
const router = useRouter();

const loading = ref<boolean>(true);
const searchText = ref<string>('');
const selectedSort = ref<string>(SORT_OPTION_ITEMS[0].value);
const selectedTaskType = ref('all');
const selectedIsCompleted = ref('0');
const pendingTaskListPath = ref<string>(getPendingTaskListPath(locale.value, '0'));
const pendingTaskTypePath = ref(getTaxonomyPath('pending-task-types', locale.value));
const { data: pendingTaskList } = usePendingTaskList(pendingTaskListPath);
const { data: pendingTaskTypes } = usePendingTaskType(pendingTaskTypePath);
const tableData = ref<Data>();
const currentPage = ref(1);
const numberOfPage = ref(0);
const totalItems = ref(0);

watch(locale, newValue => {
  loading.value = true;
  pendingTaskListPath.value = getPendingTaskListPath(newValue);
});
watch(selectedIsCompleted, newSelectedIsCompleted => {
  // alert(JSON.stringify(newSelectedIsCompleted));
  loading.value = true
  pendingTaskListPath.value = getPendingTaskListPath(locale.value, newSelectedIsCompleted)
});

watch([pendingTaskList, pendingTaskTypes, currentPage, searchText, selectedSort, selectedTaskType], ([
  newPendingTaskList,
  pendingTaskTypes,
  newCurrentPage,
  newSearchText,
  newSelectedSort,
  newSelectedTaskType,
]) => {
  const _tableData: Data = {
    rows: [],
    columns: [
      { name: 'Task Type', field_name: 'pending_task_type_name', type: 'text' },
      { name: 'School Name', field_name: 'school_name', type: 'text' },
      { name: 'Submitted on', field_name: 'created', type: 'text' },
      { name: 'Is Completed', field_name: 'field_completed', type: 'text' },
      { name: '', field_name: 'link', type: 'icon_link', icon: 'tabler-arrow-big-right-filled' },
    ],
  };
  if (newPendingTaskList === null || pendingTaskTypes === null) {
    numberOfPage.value = 0;
    tableData.value = _tableData;
    loading.value = false;
    return;
  }

  if (TASK_TYPE_FILTER_ITEMS.value.length == 0) {
    TASK_TYPE_FILTER_ITEMS.value = [{name: 'All', id: 'all'}].concat(pendingTaskTypes);
    selectedTaskType.value = TASK_TYPE_FILTER_ITEMS.value[0].id;
  }

  const filteredPendingTask = orderBy(newPendingTaskList.filter(e => { // Filtering
    let textBool = true;
    let filterBool = true;
    if (newSearchText !== '') {
      textBool = e.pending_task_type_name.toLowerCase().includes(newSearchText) ||
      e.school_name.toLowerCase().includes(newSearchText);
    }
    if (newSelectedTaskType !== 'all') {
      filterBool = e.field_pending_task_type_id === newSelectedTaskType;
    }
    return textBool && filterBool;
  }), [ newSelectedSort ], [ (newSelectedSort === 'created' ? 'desc' : 'asc' ) ]) // Sorting

  totalItems.value = filteredPendingTask.length;
  numberOfPage.value = Math.ceil(filteredPendingTask.length / ITEMS_PER_PAGE);
  const currentPendingTaskList = filteredPendingTask.slice( // Pagination
    (newCurrentPage - 1) * ITEMS_PER_PAGE,
    ((newCurrentPage - 1) * ITEMS_PER_PAGE) + ITEMS_PER_PAGE
  );
  _tableData.rows = currentPendingTaskList
  .map(e => {
    let routeLocation: RouteLocation | undefined = undefined;
    if (e.field_pending_task_type_id === PENDING_TASK_REQ_SCHOOL_ID && e.school_uuid) {
      routeLocation= router.resolve({ name: 'schools-id', params: {
        id: e.school_uuid } });
    } else if (e.field_pending_task_type_id === PENDING_TASK_UNREV_DAILY_LOG_ID &&
      e.school_uuid && e.field_content_entity_uuid
    ) {
      routeLocation= router.resolve({ name: 'schools-id-daily-logs-id2?', params: {
        id: e.school_uuid, id2: e.field_content_entity_uuid } });
    } else if (e.field_pending_task_type_id === PENDING_TASK_UNREV_REPORT_ID &&
      e.school_uuid && e.field_content_entity_uuid
    ) {
      routeLocation= router.resolve({ name: 'schools-id-reports-id2?', params: {
        id: e.school_uuid, id2: e.field_content_entity_uuid } });
    } else if ((e.field_pending_task_type_id === PENDING_TASK_REV_TEACHER_ID ||
      e.field_pending_task_type_id === PENDING_TASK_REV_APPR_TEACHER_ID) && 
      e.school_uuid && e.field_content_entity_uuid
    ) {
      routeLocation= router.resolve({ name: 'schools-id-teachers-id2?', params: {
        id: e.school_uuid, id2: e.field_content_entity_uuid } });
    }
    return { ...e, created: getFigmaDate(e.created),
      link: routeLocation !== undefined ? routeLocation.fullPath : undefined, };
  });

  tableData.value = _tableData;
  loading.value = false;
});

async function doSearch(event: Event): Promise<void> {
  const $target = event.target;
  if ($target instanceof Element === false) {
    console.log('Event target not found');
    alert(t('common.error'));
    return;
  }
  const $search = $target.querySelector<HTMLInputElement>('[name="search"]');
  if ($search === null) {
    console.log('Search input not found');
    alert(t('common.error'));
    return;
  }
  const searchValue = $search.value;
  searchText.value = searchValue.toLowerCase();
}

definePage({
  meta: {
    breadcrumb: 'Pending Task',
  }
})
</script>

<template>
  <VCard class="chat-page-layout pa-5" height="100%" width="100%">
    <VRow>
      <VCol>
        <h1>Pending Task</h1>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <div class="d-flex flex-column gap-2">
          <form 
            class="d-flex gap-1"
            @submit.prevent="doSearch($event)"
          >
            <VTextField
              label="Search"
              flat
              name="search"
              prepend-inner-icon="tabler-search"
              variant="outlined"
            />
            <VBtn
              type="submit"
            >Filter</VBtn>
          </form>
          <div class="d-flex gap-2" style="width: 60%;align-items: center;">
            <VSelect
              v-model="selectedTaskType"
              :items="TASK_TYPE_FILTER_ITEMS"
              item-title="name"
              item-value="id"
              label="Task Type"
            />
            <VSelect
              v-model="selectedSort"
              :items="SORT_OPTION_ITEMS"
              label="Sort By"
            />
            <VSelect
              v-model="selectedIsCompleted"
              :items="IS_COMPLETE_FILTER_ITEMS"
              item-title="name"
              item-value="id"
              label="Is Completed?"
            />
            <span>Total: {{ totalItems }}</span>
          </div>
        </div>
      </VCol>
    </VRow>
    <LoadingSpinner v-if="loading" />
    <VRow v-if="loading === false && tableData">
      <VCol class="d-flex flex-column gap-5">
        <CustomTable :data="tableData"/>
        <VPagination
          v-if="numberOfPage > 1"
          v-model="currentPage"
          :length="numberOfPage"
          total-visible="5"
          rounded="circle"
        />
      </VCol>
    </VRow>
  </VCard>
</template>
