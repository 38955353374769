<script setup lang="ts">
import _ from 'lodash'
import { useI18n } from 'vue-i18n'

const router = useRouter()
const route = useRoute()
const { t, locale } = useI18n();

const { data: currentUser } = await useCurrentUser2();
const selectedTab = ref<string>('7002');
const isLoading = ref(false);

const schoolsPath = ref<string>(getSchoolsPath());
const schoolStudentPath = ref(getSchoolStudentsPath())
const schoolStatusesPath = ref(getTaxonomyPath('school-statuses', locale.value));
const educationLevelsPath = ref(getTaxonomyPath('education-levels', locale.value));
const statesPath = ref(getTaxonomyPath('states', locale.value));
const districtsPath = ref(getTaxonomyPath('districts', locale.value));
const townshipsPath = ref(getTaxonomyPath('township', locale.value));

const { data: schools } = useSchools(schoolsPath, currentUser.value);
const { data: schoolStudents } = useSchoolStudents(schoolStudentPath);
const { data: schoolStatuses } = useSchoolStatus(schoolStatusesPath);
const { data: educationLevels } = useEducationLevels(educationLevelsPath);
const { data: states } = useStates(statesPath);
const { data: districts } = useDistricts(districtsPath);
const { data: townships } = useTownships(townshipsPath);

const formattedTownships = ref(undefined)
const formattedSchoolData = ref([])

// Table
const selectedSort = ref(undefined);
const selectedSortOrder = ref(undefined);
const searchText = ref('')

// Pagination
const pageSize = ref(10)
const pageLength = ref(0)
const pageCurrent = ref(1)

// Dialog
const isDialogVisible = ref(false)
const facetMenuObj = ref({
  state: undefined,
  education: undefined
})
const facetPanels = ref([0,1])
const facetFilterObj = ref({})

const sortOptions = ref([
  { value: 'field_name', title: 'Name'},
  { value: 'state', title: 'State'},
  { value: 'district', title: 'District'},
  // { value: 'created', title: 'Created'},
  { value: 'changed', title: 'Changed'}
]);

const sortOrderOptions = ref([
  { value: 'asc', title: 'Ascending'},
  { value: 'desc', title: 'Descending'},
]);

const mapData = ref({
  baseMapLayers: [
    {
      label: "OpenStreetMap.Mapnik",
      type: 'layer',
      // url: "https://geoservices.un.org/arcgis/rest/services/ClearMap_WebTopo/MapServer/tile/{z}/{y}/{x}",
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      pane: "basemap"
    }
  ],
  tilesData: [],
  markerClusterLayers: []
});

const tableData = ref({
  rows: [],
  columns: [{ name: 'School name', field_name: 'school_name', type: 'text' },
    { name: 'State', field_name: 'state',type: 'text'},
    { name: 'District', field_name: 'district',type: 'text'},
    { name: 'Changed', field_name: 'chaged_text',type: 'text'},
    { name: '', field_name: 'link',type: 'icon_link',icon: 'tabler-arrow-big-right-filled'}
  ]
})

onMounted(async () => {
  // Default Value
  selectedSort.value = sortOptions.value[0].value;
  selectedSortOrder.value = sortOrderOptions.value[0].value;
  isLoading.value = true;
  console.log(route.matched)
})

watch(
  () => [
    currentUser.value,
    schools.value,
    schoolStatuses.value,
    states.value,
    districts.value,
    townships.value,
    schoolStudents.value,
    educationLevels.value,
  ],
  ([
    currentUser,
    schools,
    schoolStatuses,
    states,
    districts,
    townships,
    schoolStudents,
    educationLevels,
  ]) => {
    if (currentUser
      && schools
      && schoolStatuses
      && states
      && districts
      && townships
      && schoolStudents
      && educationLevels
    ) {
      console.log('==== school page data ready =====')
      formatTownships();
      isLoading.value = false;
      getSchoolTable();
    }
  }
);

function formatTownships() {
  formattedTownships.value = _.map(townships.value, (d) => {
    const foundState = _.find(states.value, (s)=> {
      return s.id === d.field_state_id;
    })
    const foundDistrict = _.find(districts.value, (s)=> {
      return s.id === d.field_district_id;
    })
    d.state = foundState ? foundState : undefined;
    d.district = foundDistrict ? foundDistrict : undefined;
    return d;
  });
}

function facetUpdate(item) {
  facetFilterObj.value[item.value] = !facetFilterObj.value[item.value];
  getSchoolTable();
}

function getSchoolTable() {
  if (!searchText.value) {
    searchText.value = '';
  }
  const textSearch = searchText.value.toLowerCase();

  const allSchools = _.map(_.cloneDeep(schools.value), (school) => {
    const foundTownShip = _.find(formattedTownships.value, (ts) => {
      return ts.id === school.field_township;
    });
    const foundState = _.find(states.value, (ts) => {
      return ts.id === school.field_state;
    });
    const foundDistrict = _.find(districts.value, (ts) => {
      return ts.id === school.field_district;
    });

    const schoolStatudents = _.filter(schoolStudents.value, (ss)=> {
      return ss.school_uuid === school.uuid;
    }); 

    if (foundTownShip) {
      school.township = foundTownShip;
      const coObj = foundTownShip.field_geo.coordinates;
      school.latlng = [coObj[1], coObj[0]];
      school.tooltip = school.field_name;
    }

    school.state = foundState ? foundState.name : '';
    school.district = foundDistrict ? foundDistrict.field_name : '';

    school.school_name = school.field_name;
    school.link = `/schools/${school.uuid}`;
    // school.link_query = {school: school.uuid};
    school.educations = _.map(schoolStatudents, 'field_student_education_level');
    school.chaged_text = getFigmaDate(school.changed);
    return school;
  });

  let filteredSchool = _.filter(_.cloneDeep(allSchools), (school)=> {
    let textBool = true;
    let tabBool = true;
    if (textSearch) {
      textBool = school.school_name.toLowerCase().indexOf(textSearch) > -1;
    }
    if (selectedTab.value) {
      tabBool = school.field_school_status === Number(selectedTab.value);
    }
    return textBool && tabBool;
  });

  updateFacetFilter(filteredSchool);
  // console.log('filteredSchool', filteredSchool);
  // Filter by Facet
  const allFacetKeys = Object.keys(facetFilterObj.value);
  let selectedKeys = _.filter(allFacetKeys, (k) => {
    return facetFilterObj.value[k];
  });
  selectedKeys = _.map(selectedKeys, (d) => {return Number(d);});
  filteredSchool = _.filter(_.cloneDeep(filteredSchool), (school) => {
    let stateBool = true;
    let educationBool = true;
    stateBool = selectedKeys.indexOf(school.field_state) > -1;
    educationBool = _.intersection(school.educations, selectedKeys).length > 0;
    if (school.educations.length === 0 && selectedKeys.indexOf(10000) > -1) {
      educationBool = true;
    }
    return stateBool && educationBool;
  });


  // Sort by field
  const sortedData = _.orderBy(filteredSchool, [selectedSort.value], [selectedSortOrder.value]);

  //Current Page slice
  const tableSchool = sortedData.slice(
    (pageCurrent.value - 1) * pageSize.value,
    (pageCurrent.value - 1) * pageSize.value + pageSize.value
  );

  formattedSchoolData.value = _.cloneDeep(filteredSchool);
  pageLength.value = Math.ceil(filteredSchool.length / pageSize.value);
  console.log('tableSchool', tableSchool);
  tableData.value.rows = tableSchool;

  // Map Data
  mapData.value.markerClusterLayers = _.cloneDeep(filteredSchool);
}

function updateFacetFilter(schools) {
  const tempObj = _.cloneDeep(facetFilterObj.value);
  const allStates = _.chain(schools)
                      .orderBy('field_state')
                      .groupBy('field_state')
                      .map((v, k) => {
                        const foundState = _.find(states.value, (ts) => {
                          return ts.id === Number(k);
                        });
                        if (foundState === undefined) return undefined;
                        return {
                          label: foundState.name,
                          value: k,
                          count: v.length
                        }
                      })
                      .filter(e => e !== undefined)
                      .value()

  const stateItems = [];                
  _.each(allStates, (stateItem)=> {
    tempObj[stateItem.value] = tempObj[stateItem.value] === undefined ? true : tempObj[stateItem.value];
  })

  // console.log(schools);
  let allEducations = _.chain(schools)
                        .map('educations')
                        .flatten()
                        .groupBy()
                        .map((v, k) => {
                          const foundEdu = _.find(educationLevels.value, (ts) => {
                            // console.log(ts, k);
                            return ts.id === Number(k);
                          });
                          if (!foundEdu) return null;
                          return {
                            label: foundEdu.name,
                            value: k,
                            count: v.length
                          };
                        })
                        .value();
  // console.log(allEducations);
  allEducations = allEducations.filter(function(e){return e}); 

  allEducations.push({
    label: 'No Information',
    value: 10000,
    counnt: 0
  })

  _.each(allEducations, (eduItem)=> {
    tempObj[eduItem.value] = tempObj[eduItem.value] === undefined ? true : tempObj[eduItem.value];
  })

  facetMenuObj.value = {
    state: allStates,
    education: allEducations
  };
  facetFilterObj.value = tempObj;
}

async function doLogout(): Promise<void> {
  await useDrupalApi('/user/logout')
  router.push({name: 'login'})
}

definePage({
  meta: {
    breadcrumb: 'School',
    layout: 'default',
  },
})
</script>

<template>
  <VCard height="100%" width="100%">
    <VLayout>
      <VMain class="schools-page-container">
        <div class="pa-0 ma-0 w-100 h-100">
          <div v-if="isLoading" style="min-height: 600px;">
            <LoadingSpinner />
          </div>
          <VRow class='ma-0' v-if="!isLoading">
            <VCol cols="12" class="pt-2">
              <div>
                <h1>School</h1>
              </div>

              <div>
                <VTabs
                  class="button-group-container"
                  v-model="selectedTab"
                  @click="getSchoolTable()">
                  <VTab class="pa-0 ma-0" style="min-width:0px; display: none;" />
                  <VTab value="7002">
                    Valid Schools
                  </VTab>
                  <VTab value="7001">
                    Requesting Schools
                  </VTab>
                </VTabs>
              </div>

              <div>
                <VRow style="min-height: 600px;" class='ma-0'>
                  <VCol cols="4" class="pt-2">
                    <MapChart
                      :data="mapData"
                    />
                  </VCol>
                  <VCol cols="8" class="pt-2">
                    <div>
                      <div>
                        <h2 v-if="selectedTab === '7001'">Requesting Schools</h2>
                        <h2 v-if="selectedTab === '7002'">Valid Schools</h2>
                      </div>
                      <div class="mt-3 mb-1 d-flex align-start">
                        <VTextField
                          v-model="searchText"
                          clearable
                          label="Search"
                          prepend-inner-icon="tabler-search"
                          variant="outlined"
                          flat
                          hide-details
                          @update:modelValue="getSchoolTable()"
                        />
                        <VBtn
                          @click="isDialogVisible = true"
                          class="ms-2">Filter</VBtn>
                      </div>

                      <div class="mb-3 d-flex align-start">
                        <div>
                          <VSelect
                            v-model="selectedSort"
                            :items="sortOptions"
                            @update:model-value="getSchoolTable()"
                          />
                        </div>
                        <div class="ms-2">
                          <VSelect
                            v-model="selectedSortOrder"
                            :items="sortOrderOptions"
                            @update:model-value="getSchoolTable()"
                          />
                        </div>
                        <div class="ms-2 mt-2">Total {{formattedSchoolData.length}} schools</div>
                      </div>

                      <div>
                        <CustomTable :data="tableData"/>
                      </div>

                      <div class="mt-3 mb-3">
                        <VPagination
                          v-model="pageCurrent"
                          @update:model-value="getSchoolTable()"
                          :length="pageLength"
                          :total-visible="5"
                          rounded="circle">
                        </VPagination>
                      </div>
                    </div>
                  </VCol>
                </VRow>
              </div>

              <!-- <VBtn color="error" @click="doLogout()">Logout</VBtn> -->
            </VCol>
          </VRow>   


          <VDialog
              v-model="isDialogVisible"
              width="auto">
              <v-card
                width="500"
                title="Filter">
                <div>
                  <div class="facet-filter-container">
                    <v-expansion-panels expand multiple v-model="facetPanels">
                      <v-expansion-panel v-if="facetMenuObj.state !== undefined">
                        <v-expansion-panel-title>
                          State
                          <template v-slot:actions="{ expanded }">
                            <v-icon
                              :icon="expanded ? 'tabler-chevron-up' : 'tabler-chevron-down'"></v-icon>
                          </template>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                          <div>
                            <v-list>
                              <v-list-item
                                @click="facetUpdate(item)"
                                 v-for="(item, index) in facetMenuObj.state">
                                <template v-slot:prepend>
                                  <v-list-item-action start>
                                    <VCheckboxBtn
                                      :model-value="facetFilterObj[item.value]"></VCheckboxBtn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title>{{item.label}}</v-list-item-title>

<!--                                 <template v-slot:append>
                                  <v-badge
                                    color="primary"
                                    :content="item.count"
                                    inline></v-badge>
                                </template> -->
                              </v-list-item>
                            </v-list>
                          </div>
                        </v-expansion-panel-text>
                      </v-expansion-panel>

                      <v-expansion-panel v-if="facetMenuObj.education !== undefined">
                        <v-expansion-panel-title>
                          Education Level
                          <template v-slot:actions="{ expanded }">
                            <v-icon
                              :icon="expanded ? 'tabler-chevron-up' : 'tabler-chevron-down'"></v-icon>
                          </template>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                          <div>
                            <v-list>
                              <v-list-item
                                @click="facetUpdate(item)"
                                 v-for="(item, index) in facetMenuObj.education">
                                <template v-slot:prepend>
                                  <v-list-item-action start>
                                    <VCheckboxBtn
                                      :model-value="facetFilterObj[item.value]"></VCheckboxBtn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title>{{item.label}}</v-list-item-title>

<!--                                 <template v-slot:append>
                                  <v-badge
                                    color="primary"
                                    :content="item.count"
                                    inline></v-badge>
                                </template> -->
                              </v-list-item>
                            </v-list>
                          </div>
                        </v-expansion-panel-text>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                </div>

                <template v-slot:actions>
                  <VBtn
                    class="ms-auto"
                    text="Close"
                    @click="isDialogVisible = false"
                  ></VBtn>
                </template>
              </v-card>
            </VDialog>      
        </div>
      </VMain>
    </VLayout>
  </VCard>
</template>

<style lang="scss">
  .schools-page-container {

  }
  .facet-filter-container {
    max-height: 80vh;
    overflow: auto;
  }
</style>
